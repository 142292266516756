<template>
  <div class="card">
    <div class="card-body">
      <div class="border p-4 rounded">
        <div class="text-center">
          <!--          <pre>-->
          <!--            {{ errors.email }}-->
          <!--            {{ isLoading }}-->
          <!--          </pre>-->
          <h3 class="">Inscription</h3>
          <!-- <p>
            Don't have an account yet?

            <a href="authentication-signup.html">Inscrivez-vous ici</a>
          </p> -->
        </div>
        <!-- <div class="d-grid">
          <a class="btn my-4 shadow-sm btn-white" href="javascript:;">
            <span class="d-flex justify-content-center align-items-center">
              <img
                        class="me-2"
                        src="assets/images/icons/search.svg"
                        width="16"
                        alt="Image Description"
                      />
              <span>Connexion with Google</span>
            </span>
          </a>
          <a href="javascript:;" class="btn btn-facebook"
            ><i class="bx bxl-facebook"></i>Connexion with Facebook</a
          >
        </div> -->
        <div class="login-separater text-center mb-4">
          <span>OU CONNECTEZ-VOUS AVEC EMAIL</span>
          <hr/>
        </div>
        <div class="form-body">
          <Form class="row g-3" @submit="handleRegister" :validation-schema="schema">
            <div class="" v-if="!successful">
              <div class="col-12">
                <label for="email" class="form-label">Email Address</label

                >
                <Field
                    id="email"
                    name="email"
                    type="text"
                    class="form-control"

                />
                <ErrorMessage name="email" class="error-feedback text-danger text-muted"/>
              </div>

              <div class="col-md-6 text-end">
                <a href="#">Mot de passe oublié ?</a>
              </div>
              <div class="col-12">
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">
                  <span class="" v-if="loading">
                    <b-spinner small variant="light"></b-spinner>
                  </span>
                    <span class="" v-else>
                   CONNEXION
                  </span>
                  </button>
                </div>
              </div>
            </div>

          </Form>

          <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'"
          >
            {{ message }}
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import {axiosService} from "../../services/axios.service";

export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    const schema = yup.object().shape({

      email: yup
          .string()
          .required("Email is required!")
          .email("Email is invalid!")
          .max(50, "Must be maximum 50 characters!"),

    })
    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    }
  },


  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/admin/dashboard");
    }
  },

  methods: {
     handleRegister(user) {
      alert('send')
      this.message = "";
      this.successful = false;
      this.loading = true;

       axiosService.post("admin-register", user).then(
          (data) => {
            this.message = data.message;
            this.successful = true;
            this.loading = false;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            this.loading = false;
          }
      );

    }
  }
}
</script>

<style></style>
