import { createApp } from 'vue'
import App from './App.vue'

import BootstrapVue3 from 'bootstrap-vue-3'

import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

// import './assets/index.scss'
import router from './router'
import store from './store'

/* eslint-disable */

createApp(App).
    use(store).
    use(router).
    use(moshaToast).
    use(BootstrapVue3).
    mount('#app')
