<template>

  <table id="example2" class="table table-striped table-bordered">
    <thead>
    <tr>
      <th  v-for="(heaser,index) in tableHeadecitoyens" :key="index" >{{heaser}}</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr  v-for="(edition,index) in paginateData" :key="index">
      <td   v-for="(item,i) in tableHeadecitoyens" :key="i" > {{edition[item]}}</td>
        <td>
          <div class="d-flex order-actions">
            <a
                href="#"

                class="text-danger bg-light-danger border-0"
            ><i class="bx bxs-trash"></i
            ></a>

          </div>
        </td>

    </tr>

    </tbody>
    <tfoot>
    <tr>
      <th  v-for="(heaser,index) in tableHeadecitoyens" :key="index" >{{heaser}}</th>

      <th>Actions</th>
    </tr>
    </tfoot>
  </table>

      <b-pagination
          v-model="currentPage"
          :total-rows="citoyens.length"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
      ></b-pagination>



  <div :class="citoyens"></div>
<!--  <pre>-->
<!-- -->
<!--    {{data }}-->

<!--  </pre>-->
</template>

<script setup>
import {computed, defineProps, ref} from 'vue'

const currentPage = ref(1)
const perPage = ref(10)
 const props = defineProps({
  data:{
    type:Array,
  }
})


const tableHeadecitoyens = computed(()=>{
  return Object.keys( props.data[0][0])
})
const citoyens = computed(()=>{
  return  props.data[0] ;
})

const paginateData = computed(()=>{

 return  citoyens.value.slice(perPage.value* ( currentPage.value-1)  ,currentPage.value*perPage.value)
})

</script>

<style scoped>

</style>
