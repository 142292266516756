
import  {watch} from 'vue'
import {ref} from "@vue/reactivity";

import { toMutipartedForm } from '@/_helpers/formFormater'
import {axiosService} from "../services/axios.service";
import _ from "lodash";


const isLoading = ref(false)
const httperrors = ref({})
const httperrorsmessage = ref(null)
const lists = ref([])
const singleData = ref({})
const q = ref('')
const baseUrl = ref('')
const message = ref('')
const etat = ref(false)


const getUser = async (email,password)=>{
    isLoading.value = true
    httperrors.value = {}
    httperrorsmessage.value = null
    lists.value = null
    try{
        const res = await  axiosService.get(baseUrl,{email, password})
        lists.value = res.data
        httperrorsmessage.value = null
        httperrors.value = {}
        isLoading.value = false

    }catch (error) {
        httperrorsmessage.value = error.response.data.message
        httperrors.value = error.response.data.errors
        isLoading.value = false
    }

}

const show = async ()=>{
    isLoading.value = true
    try{
        const  res = await axiosService.get(baseUrl+'/create')
        isLoading.value = false
        httperrors.value = {}
        singleData.value = res.data
    }catch (e) {
        console.log('e.response.data')
        isLoading.value = false
        httperrors.value = e.response.data
    }
}


const deleteModel = async (id)=>{
    isLoading.value = true
    isLoading.value = null
    try {
         await axiosService.delete(baseUrl.value+'/'+id)
        isLoading.value = false
        isLoading.value = null
    }catch (e) {
        console.log(e.response)
        isLoading.value = false
        isLoading.value = e.response
    }
}



const getSingle = async (id)=>{
    isLoading.value = true
    try{
        const  res = await axiosService.get(baseUrl.value+'/'+id)
        isLoading.value = false
        httperrors.value = {}
        // singleData.value = res.data.data.attributes
        singleData.value = res.data.data

    }catch (e) {
        console.log('e.response.data')
        isLoading.value = false
        httperrors.value = e.response.data
    }
}

const save = async (data)=>{
      isLoading.value = true
    // let message =  'L\'untilisateur a bien été ajouté !'
    httperrors.value = {}
    etat.value = false

    try{
        if(data.id){
            const form = toMutipartedForm(data,'edit')
            const  res  = await axiosService.post(baseUrl.value+'/'+data.id+'?_method=PUT',form)
            message.value= 'L\'untilisateur '+ res.data.email +' a bien été modifier'
        }else {
            const form = toMutipartedForm(data)
            // const form =  (data)
            const  res  = await axiosService.post(baseUrl.value,form)
            data.value = res.data
            message.value= 'L\'untilisateur '+ res.data.email +' a bien été ajouté '
        }
        isLoading.value = false
        httperrors.value = {}
        etat.value = true

        // Vue.$toast.open({
        //     message:message,
        //     type: 'success',
        //     position:"top-right"
        // });

    }catch (e) {
        console.log(e);
        isLoading.value = false
        httperrors.value =  e.response.data.errors
        etat.value = false
        message.value= 'Merci de corriger vos erreurs ! '
        // Vue.$toast.open({
        //     message: 'Attention corriger vos erreurs !',
        //     type: 'error',
        //     position:"top-right"
        // });
    }
}


const getResults = async (page=1) => {
    isLoading.value = true
    httperrors.value = {}
    try {
        let url = baseUrl.value+'?page='+page
        if (q)
            url = url+'&q='+q.value
        const res = await axiosService.get(url)
        lists.value = res.data
        isLoading.value = false
        httperrors.value = {}
    }catch (e) {
        // console.log(e.response)
        isLoading.value = false
        httperrors.value = e.response
    }
}


const ab =  _.debounce(function( ){
    getResultsLink()
},1000)
watch(q,ab)



const getResultsLink = async (link= null     ) => {

    isLoading.value = true
    httperrors.value = {}
    try {
        let url = link ? link : baseUrl.value+'?page='+1
        if (q.value)
            url = url+'&q='+q.value
        const res = await axiosService.get(url)
        lists.value = res.data
        isLoading.value = false
        httperrors.value = {}
    }catch (e) {
        // console.log(e.response)
        isLoading.value = false
        httperrors.value = e.response
    }
}





const useFect = (url)=>{
    baseUrl.value = url
    return { isLoading,getUser,lists,singleData,httperrors,message,save,show,getSingle,deleteModel,getResults,getResultsLink, q,etat}
}

export  default  useFect