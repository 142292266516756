<template>
  <div class="d-flex justify-content-between">
    <div class="col-lg-8">
      <input class="form-control" v-model="q" placeholder="Rechercher" type="search" name="" id=""  @keyup="sercheProduct">
    </div>
    <div class="col-lg-1">
      <b-form-select v-model="pageSize" :options="[4,8,10,15]"></b-form-select>
    </div>
  </div>
  <hr>
  <table class="table table-bordered mb-0">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Nom</th>
      <th scope="col">Prix</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="prod in paginateDatas" :key="prod.id">
      <th scope="row">{{prod.id}}</th>
      <td>{{prod.name}}</td>
      <td>{{prod.price}}</td>
      <td>
        <button @click.prevent="emits('update-product',prod)" class="btn btn-outline-success btn-sm p-2 mx-2"> <i class="bx bx-pencil "></i></button>
        <button @click.prevent="deleteItem(prod)" class="btn btn-outline-danger btn-sm p-2"> <i class="bx bx-trash-alt "></i></button>
      </td>
    </tr>

    </tbody>
  </table>
  <hr>
<div class="d-flex justify-content-between">


  <div class="">
    <span>Total : {{productTotal}} </span>
  </div>


  <ul class="pagination">
    <li class="page-item" :class="{disabled:currentPage<=0}">
      <a class="page-link" href="#"   @click.prevent="prev">Previous</a>
    </li>

    <li v-for="(p,index) in pageCount" :key="index" class="page-item" :class="{active:currentPage===p}">
      <a href="#" class="page-link" @click.prevent="currentPage=p">{{p}}
      </a>
    </li>

    <li class="page-item"  :class="{disabled:currentPage >= pageCount}">
      <a class="page-link" href="#" @click.prevent="next">Next</a>
    </li>
  </ul>


</div>

</template>

<script setup>
import {computed, defineEmits, defineProps} from 'vue'
import {axiosService} from "../../../services/axios.service";
import {ref} from "@vue/reactivity";


const emits = defineEmits(['delete-product','update-product'])
const props = defineProps({
    datas:{type:Object,default:()=>{}},
    baseUrl:String
  })

const q = ref('')
const productTotal = ref(props.datas.data.length)
const currentPage = ref(1)
const pageSize = ref(4)


const paginateDatas = computed({
  get: () =>{
    let index =( currentPage.value -1 ) *  pageSize.value
    return props.datas.data.slice(index ,index + pageSize.value )
  },
  set: (val) => {
    let index =( currentPage.value -1 ) *  pageSize.value
    return props.datas.data.slice(index ,index + pageSize.value ).filter(pro=>pro.name.toLowerCase().indexOf(val.toLowerCase())  >=0)
  }

})

const next = ()=>{ currentPage.value++ }
const prev = ()=>{ currentPage.value-- }


const sercheProduct = ()=>{
  paginateDatas.value  = q.value

}
const pageCount = computed(()=>{
  return  Math.ceil(productTotal.value/pageSize.value)
})
  const deleteItem  = async (prod)=>{

  const rep = confirm('Supprimer me produit ?')
    if (!rep)
        return rep

    try {
      await axiosService.delete(props.baseUrl+'/'+prod.id)
      emits('delete-product')
    }catch (e) {
      console.log(e)
    }
  }

</script>

<style scoped>

</style>