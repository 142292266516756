<template>
  <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
  </div>
  <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4" v-if="!loading">
    <div class="col">
      <div class="card radius-10 overflow-hidden bg-danger">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <p class="mb-0 text-white">Total des bénéficaires </p>
              <h5 class="mb-0 text-white">{{novissis.profils_count}}</h5>
            </div>
            <div class="ms-auto text-white">
              <i class="bx bx-cart font-30"></i>
            </div>
          </div>
        </div>
        <div class="" id="chart1"></div>
      </div>
    </div>
    <div class="col">
      <div class="card radius-10 overflow-hidden bg-primary">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <p class="mb-0 text-white">Montant</p>
              <h5 class="mb-0 text-white">{{ converToCfa(novissis.montant) }}</h5>
            </div>
            <div class="ms-auto text-white">
              <i class="bx bx-wallet font-30"></i>
            </div>
          </div>
        </div>
        <div class="" id="chart2"></div>
      </div>
    </div>
    <div class="col">
      <div class="card radius-10 overflow-hidden bg-warning">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <p class="mb-0 text-dark">Total Users</p>
              <h5 class="mb-0 text-dark">{{novissis.profils_count}}</h5>
            </div>
            <div class="ms-auto text-dark">
              <i class="bx bx-group font-30"></i>
            </div>
          </div>
        </div>
        <div class="" id="chart3"></div>
      </div>
    </div>
    <div class="col">
      <div class="card radius-10 overflow-hidden bg-success">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <p class="mb-0 text-white">Déja </p>
              <h5 class="mb-0 text-white">{{novissis.deja}}</h5>
            </div>
            <div class="ms-auto text-white">
              <i class="bx bx-chat font-30"></i>
            </div>
          </div>
        </div>
        <div class="" id="chart4"></div>
      </div>
    </div>
  </div>
  <!--end row-->

  <div class="card">
    <div class="card-body">
       <h6>Edition :  {{novissis?.name}}</h6>
       <h6>Date début : {{  convertTodat( novissis?.datedeb ) }}</h6>
       <h6>Date de fin  :  {{convertTodat( novissis?.datefin ) }}</h6>
    </div>
  </div>

  <div class="card"  v-if="currentuser.type==='superadmin'">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-4"  v-for="p in novissis?.packages" :key="p.id">
          <div class="">
            <h5> Packege : {{p.name}}</h5>
            <div class="">
              <h6>Montant :  {{p.totalFormat}}</h6>
                <span v-for="product in p.products" :key="product.id" class="badge bg-dark m-1 p-1">{{product.name}}</span>

            </div>


            <PackageFileUpload :package_id="p.id" :editionnovissi_id="novissis.id"/>
            <hr>
            <div class="">
              <package-edition-profils-infos :package_id="p.id" :editionnovissi_id="novissis.id"/>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-4">
          <router-link exact :to="`/admin/novissi/${route.params.id}/dashbordnovissi/`" class="btn btn-outline-danger w-100 btn-lg">Notifications sms </router-link>
        </div>

        <div class="col-lg-4">
          <router-link :to="`/admin/novissi/${route.params.id}/dashbordnovissi/beneficiaires`"  class="btn btn-outline-dark w-100 btn-lg">Bénéficiaires </router-link>
        </div>
        <div class="col-lg-4">
          <router-link :to="`/admin/novissi/${route.params.id}/dashbordnovissi/operationeditions`" class="btn btn-outline-success w-100 btn-lg">Déja </router-link>
        </div>

      </div>
    </div>
  </div>

  <div class="card radius-10 w-100">
    <div class="card-body">
      <router-view ></router-view>
  </div>
  </div>

    <hr>
  <!--
  <div class="row">
    <div class="col-12 col-xl-4 d-flex">
      <div class="card radius-10 w-100">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="mb-0">New Customers</h5>
            </div>
            <div class="font-22 ms-auto">
              <i class="bx bx-dots-horizontal-rounded"></i>
            </div>
          </div>
        </div>
        <div class="customers-list p-3 mb-3">
          <div
              class="customers-list-item d-flex align-items-center border-top border-bottom p-2 cursor-pointer"
          >
            <div class="">
              <img
                  src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg"
                  class="rounded-circle"
                  width="46"
                  height="46"
                  alt=""
              />
            </div>
            <div class="ms-2">
              <h6 class="mb-1 font-14">Emy Jackson</h6>
              <p class="mb-0 font-13 text-secondary">emy_jac@xyz.com</p>
            </div>
            <div class="list-inline d-flex customers-contacts ms-auto">
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-envelope"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-phone"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bx-dots-vertical-rounded"></i
              ></a>
            </div>
          </div>

          <div
              class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer"
          >
            <div class="">
              <img
                  src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg"
                  class="rounded-circle"
                  width="46"
                  height="46"
                  alt=""
              />
            </div>
            <div class="ms-2">
              <h6 class="mb-1 font-14">Paul Benn</h6>
              <p class="mb-0 font-13 text-secondary">pauly.b@xyz.com</p>
            </div>
            <div class="list-inline d-flex customers-contacts ms-auto">
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-envelope"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-phone"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bx-dots-vertical-rounded"></i
              ></a>
            </div>
          </div>
          <div
              class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer"
          >
            <div class="">
              <img
                  src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg"
                  class="rounded-circle"
                  width="46"
                  height="46"
                  alt=""
              />
            </div>
            <div class="ms-2">
              <h6 class="mb-1 font-14">Winslet Maya</h6>
              <p class="mb-0 font-13 text-secondary">winslet_02@xyz.com</p>
            </div>
            <div class="list-inline d-flex customers-contacts ms-auto">
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-envelope"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-phone"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bx-dots-vertical-rounded"></i
              ></a>
            </div>
          </div>
          <div
              class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer"
          >
            <div class="">
              <img
                  src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg"
                  class="rounded-circle"
                  width="46"
                  height="46"
                  alt=""
              />
            </div>
            <div class="ms-2">
              <h6 class="mb-1 font-14">Bruno Bernard</h6>
              <p class="mb-0 font-13 text-secondary">bruno.b@xyz.com</p>
            </div>
            <div class="list-inline d-flex customers-contacts ms-auto">
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-envelope"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-phone"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bx-dots-vertical-rounded"></i
              ></a>
            </div>
          </div>
          <div
              class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer"
          >
            <div class="">
              <img
                  src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg"
                  class="rounded-circle"
                  width="46"
                  height="46"
                  alt=""
              />
            </div>
            <div class="ms-2">
              <h6 class="mb-1 font-14">Merlyn Dona</h6>
              <p class="mb-0 font-13 text-secondary">merlyn.d@xyz.com</p>
            </div>
            <div class="list-inline d-flex customers-contacts ms-auto">
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-envelope"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-phone"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bx-dots-vertical-rounded"></i
              ></a>
            </div>
          </div>
          <div
              class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer"
          >
            <div class="">
              <img
                  src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg"
                  class="rounded-circle"
                  width="46"
                  height="46"
                  alt=""
              />
            </div>
            <div class="ms-2">
              <h6 class="mb-1 font-14">Alister Campel</h6>
              <p class="mb-0 font-13 text-secondary">alister_42@xyz.com</p>
            </div>
            <div class="list-inline d-flex customers-contacts ms-auto">
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-envelope"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bxs-phone"></i
              ></a>
              <a href="javascript:;" class="list-inline-item"
              ><i class="bx bx-dots-vertical-rounded"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-4 d-flex">
      <div class="card radius-10 w-100 overflow-hidden">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="mb-0">Store Metrics</h5>
            </div>
            <div class="font-22 ms-auto">
              <i class="bx bx-dots-horizontal-rounded"></i>
            </div>
          </div>
        </div>

        <div class="store-metrics p-3 mb-3">
          <div class="card mt-3 radius-10 border shadow-none">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Products</p>
                  <h4 class="mb-0">856</h4>
                </div>
                <div
                    class="widgets-icons bg-light-primary text-primary ms-auto"
                >
                  <i class="bx bxs-shopping-bag"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="card radius-10 border shadow-none">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Customers</p>
                  <h4 class="mb-0">45,241</h4>
                </div>
                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                  <i class="bx bxs-group"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="card radius-10 border shadow-none">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Categories</p>
                  <h4 class="mb-0">98</h4>
                </div>
                <div
                    class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="bx bxs-category"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="card radius-10 border shadow-none">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Orders</p>
                  <h4 class="mb-0">124</h4>
                </div>
                <div class="widgets-icons bg-light-info text-info ms-auto">
                  <i class="bx bxs-cart-add"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="card radius-10 border shadow-none mb-0">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Vendors</p>
                  <h4 class="mb-0">55</h4>
                </div>
                <div
                    class="widgets-icons bg-light-warning text-warning ms-auto"
                >
                  <i class="bx bxs-user-account"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-4 d-flex">
      <div class="card radius-10 w-100">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="mb-1">Top Products</h5>
            </div>
            <div class="font-22 ms-auto">
              <i class="bx bx-dots-horizontal-rounded"></i>
            </div>
          </div>
        </div>

        <div class="product-list p-3 mb-3">
          <div
              class="d-flex align-items-center py-3 border-bottom cursor-pointer"
          >
            <div class="product-img me-2">
              <img src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg" alt="product img" />
            </div>
            <div class="">
              <h6 class="mb-0 font-14">Black Boost Chair</h6>
              <p class="mb-0">148 Sales</p>
            </div>
            <div class="ms-auto">
              <h6 class="mb-0">$246.24</h6>
            </div>
          </div>

          <div
              class="d-flex align-items-center py-3 border-bottom cursor-pointer"
          >
            <div class="product-img me-2">
              <img src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg" alt="product img" />
            </div>
            <div class="">
              <h6 class="mb-0 font-14">Red Single Sofa</h6>
              <p class="mb-0">122 Sales</p>
            </div>
            <div class="ms-auto">
              <h6 class="mb-0">$328.14</h6>
            </div>
          </div>


          <div
              class="d-flex align-items-center py-3 border-bottom cursor-pointer"
          >
            <div class="product-img me-2">
              <img src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg" alt="product img" />
            </div>
            <div class="">
              <h6 class="mb-0 font-14">Four Leg Chair</h6>
              <p class="mb-0">154 Sales</p>
            </div>
            <div class="ms-auto">
              <h6 class="mb-0">$425.24</h6>
            </div>
          </div>

          <div
              class="d-flex align-items-center py-3 border-bottom cursor-pointer"
          >
            <div class="product-img me-2">
              <img src="http://127.0.0.1:8000/images/static/imgplaceholder.jpg" alt="product img" />
            </div>
            <div class="">
              <h6 class="mb-0 font-14">Blue Light T-Shirt</h6>
              <p class="mb-0">186 Sales</p>
            </div>
            <div class="ms-auto">
              <h6 class="mb-0">$149.34</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


-->


<!--    <pre>-->

<!--        {{ novissis    }}-->
<!--      {{loading   }}-->
<!--      {{fetchData }}-->
<!--      {{infos }}-->
<!--    </pre>-->
</template>
<script setup>
// import { useStore } from 'vuex'
import { useRoute} from 'vue-router'
import {computed, onMounted} from "vue";
import useGetdata from "../../composable/useGetdata";
import converToCfa from "../../_helpers/converTocfa";
import convertTodat from "../../_helpers/dateHerper";
import PackageFileUpload from "../../components/elements/PackageFileUpload";
import PackageEditionProfilsInfos from "../../components/elements/package-edition-profils-infos";

// const store = useStore()
const route = useRoute()
// const infos = store.state


const { datas, loading,fetchData } = useGetdata('/admin/novissis/'+route.params.id)

console.log('dashbordnovissi = '+route.params.id)

const novissis = computed(()=>datas.value?.data)
onMounted(async ()=>{
  await  fetchData()
})

</script>
