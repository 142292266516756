<template>
  <Breadcrumb :menu="bread"/>

  <div class="" v-if="operationId">
    <b-alert show variant="success">
      <h4 class="alert-heading"> L'opération pour le bénéficiaire {{user.profil.fullname}} a déja été fait</h4>
      <p>
        L'opération pour le bénéficiaire {{user.profil.fullname}} a déja été fait
      </p>
      <hr />
      <p class="mb-0">
        L'opération pour le bénéficiaire {{user.profil.fullname}} a déja été fait
      </p>
    </b-alert>


  </div>

  <div class="row" >
   <div class="container">
     <div class="card">
       <div class="card-body">
         <div class="row">
           <div class="col-lg-4">
             <h5>Générer le code de vérification </h5>
             <hr>
            <div class="d-flex justify-content-between">
              <button @click="generer" class="btn" :class="{'btn-danger':!code,'btn-success':code}">
                <b-spinner small v-if="loading" ></b-spinner>
               <span v-else> Générer le code</span>

              </button>
              <h2>{{codesms}}</h2>
            </div>
             <hr>

           </div>
           <div class="col-lg-8">

             <ul class="list-group list-group-flush">

               <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                 <h6 class="mb-0">
                  Edition</h6>
                 <span class="text-dark">{{editionnovissi.name}}</span>
               </li>

               <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                 <h6 class="mb-0">
                 Date de début de Edition</h6>
                 <span class="text-dark">{{editionnovissi.datedeb}}</span>
               </li>

               <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                 <h6 class="mb-0">
                   Date de fin de Edition</h6>
                 <span class="text-dark">{{editionnovissi.datefin}}</span>
               </li>

               <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                 <h6 class="mb-0">
                   Montant de l'edition</h6>
                 <span class="text-dark">{{editionnovissiProfil?.package.totalFormat}}</span>
               </li>

             </ul>

           </div>
         </div>
       </div>
     </div>
   </div>
  </div>
  <div class="row">




    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
<!--          <div class="d-flex flex-column align-items-center text-center">-->
<!--            <img :src="user.img" alt="Admin" class="rounded-circle p-1 bg-primary" width="110">-->
<!--            <div class="mt-3">-->
<!--              <h4>{{user.profil.fullname}}</h4>-->
<!--              <p class="text-secondary mb-1">Full Stack Developer</p>-->
<!--              <p class="text-muted font-size-sm">{{user.profil.telephone}}</p>-->
<!--              <button class="btn btn-primary">Follow</button>-->
<!--              <button class="btn btn-outline-primary">Message</button>-->
<!--            </div>-->
<!--          </div>-->
          <h5>Bénéficiaire</h5>
          <hr>


          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Nom prénom</h6>
              <span class="text-secondary">{{user.profil.fullname}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Téléphone</h6>
              <span class="text-secondary">{{user.profil.telephone}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Sexe</h6>
              <span class="text-secondary">{{user.profil.sexe}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Date de naissance</h6>
              <span class="text-secondary">{{user.profil.dateness}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Type de piece</h6>
              <span class="text-secondary">{{user.profil.typepiece}} | {{user.profil.numpiece}} </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
            <h5>Package | {{editionnovissiProfil?.package.totalFormat}}</h5>
          <hr>
          <div class="">
            <span class="badge bg-dark p-2 m-1" v-for="prod in editionnovissiProfil?.package.products" :key="prod.id"> {{prod.name}}</span>
          </div>
        </div>
      </div>
    </div>


    <div class="col-lg-8">
      <b-alert show variant="danger" v-if="saveErrors">
        <div v-for="(errs,index) in Object.values(saveErrors)" :key="index">

          {{errs.join(' ')}}

        </div>
      </b-alert>

      <form class="card"  @submit.prevent="onSubmit">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Boutique</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <b-form-select    v-model="form.boutique_id" :options="nosBoutiques"></b-form-select>


            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Action</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <b-form-select v-model="form.etat_id" :options="nosEtats"></b-form-select>
            </div>
          </div>



          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Edition Novissi</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <input disabled type="text" class="form-control" :value="editionnovissi.name">
            </div>
          </div>

         <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Edition Novissi</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <input disabled type="text" class="form-control" :value="editionnovissiProfil?.package.name">

            </div>
          </div>





          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Observation</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <textarea rows="5" class="form-control"  v-model="form.observation"></textarea>
            </div>
          </div>


          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-9 text-secondary">

              <button class="btn  btn-lg px-4 float-end" :disabled="loadingSave" :class="{'btn-danger':!operationId,'btn-primary':operationId}">
                <b-spinner small v-if="loadingSave" class="mx-2 "/>
                <span v-if="!operationId">Enregistrer l'opération</span>
                <span v-else>Modifier l'opération</span>
              </button>
            </div>
          </div>



        </div>
      </form>



    </div>
  </div>
  <pre>

<!--    {{ editionnovissiProfil }}-->
<!--    {{ editionnovissi['name'] }}-->
<!--    {{ message }}-->
<!--    {{ user }}-->
  </pre>
</template>

<script setup>
import {useRoute} from "vue-router";

const route = useRoute()
import Breadcrumb from "../../../components/elements/breadcrumb";
import {ref} from "@vue/reactivity";
import {computed, onMounted} from "vue";
import {axiosService} from "../../../services/axios.service";
import useSave from "../../../composable/useSave";
import {createToast} from "mosha-vue-toastify";

const id = ref(route.params.id)
const userid = ref(route.params.userid)
const user = ref({
  profil:{}
});
const datas = ref({});

const operationId = ref(false);
const editionnovissiProfil = ref(null);

const codesms = ref('');
const editionnovissi = ref('');
const boutiques = ref([]);
const etats = ref([])
const loadingSave = ref(false)
const saveErrors = ref(false)

const form = ref({
  boutique_id:'',
  etat_id:'',
  editionnovissi_id:id.value,
  user_id:userid.value,
  observation:'',
  codesms,

})


const nosBoutiques = computed(()=>{
  const eles = boutiques.value.map(b=> {
    return {
      value: b.id,text: b.name
    }
  })
  return [{value:null,text:'Selectionner une boutique'},...eles]
})
const nosEtats = computed(()=>{

  const eles =  etats.value.map(b=> {
    return {
      value: b.id,text: b.name
    }
  })
  return [{value:null,text:"Selectionner l'état de l'opération"},...eles]
})


const bread = {
  title:null,
  link:'/admin/novissi/store',
  links:[
    { title:'Novissi',link:'/admin/novissi/index'},
    { title:'Liste des Novissi',link:'/admin/novissi/index'},
    { title:"Tableau de bord de l' édition ",link:`/admin/novissi/${id.value}/dashbordnovissi`},
    { title:'Liste des Novissi',link:'/admin/novissi/1/dashbordnovissi'}
  ]
}

const { loading,etat,save,getdata} = useSave()


onMounted(()=>{


  document.title =  'Liste des bénéficiaires'
  loadUser()
  loadEditionNovissi()

})




const loadUser = async ()=>{

  try {
    const rep =await axiosService.get('/admin/operations-user/'+userid.value+'/novissis/'+id.value)
    datas.value = rep.data
    user.value = rep.data.user
    etats.value = rep.data.etats
    boutiques.value = rep.data.boutiques
    editionnovissiProfil.value = rep.data.editionnovissiProfil
    codesms.value = rep.data.editionnovissiProfil.codesms

    operationId.value = rep.data.existsOperation.id
    form.value = rep.data.existsOperation


  }catch (e) {
    console.log(e)
  }
}
const loadEditionNovissi = async ()=>{

  try {
    const rep =await axiosService.get('/admin/editionnovissi/'+id.value)
    editionnovissi.value = rep.data.data


  }catch (e) {
    console.log(e)
  }
}

const generer = async ()=>{
  const data = {'editionnovissi_id':id.value,'profil_id':user.value.profil.id }

  await save('/admin/generate-code-bebeficiaire',data)
  if (etat.value){
    codesms.value =  getdata.value.codesms
  }
}

const onSubmit =async ()=>{
  loadingSave.value = true
  saveErrors.value = null

  let res = null
  try {

    if (  operationId.value){
       res = await axiosService.post('/admin/operations/'+  operationId.value+'/?_method=PUT',form.value)
    }else{
       res = await axiosService.post('/admin/operations',form.value)
    }
    console.log(res)
    loadingSave.value = false
    createToast('Opération enregistré avec success' ,{type:"success",timeout:10000})
    saveErrors.value = null
    loadUser()
  }catch (e) {
    console.log(e)
    loadingSave.value = false
    saveErrors.value = e.response.data.errors
    createToast('Merci de corriger vos erreurs !' ,{type:"danger"})
  }
}



</script>

<style >
.form-select select{
  background: #000;
}
</style>
