<template>
  <h3 class="mb-1">Les pénéficiaires </h3>

  <div class="">
    <ListFrofils v-if="novissi_id" :editerid="novissi_id" />
  </div>
</template>

<script setup>
import ListFrofils from "../../components/elements/editionnovissi/ListFrofils";
import {useRoute} from "vue-router";
import {ref} from "@vue/reactivity";
import {onMounted} from "vue";
const route = useRoute()

const novissi_id = ref(0)

onMounted(()=>{
  novissi_id.value = route.params.id

})
</script>

<style scoped>

</style>
