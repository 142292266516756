import {axiosService} from "../services/axios.service";
import {ref} from "@vue/reactivity";

const loading = ref(false);
const formerrors = ref({});
const etat = ref(false);
const message = ref('');
const getdata = ref({});


const save = async (baseUrl,data)=>{
    loading.value = true
    formerrors.value = {}
    etat.value = false
    try{
        if(data.id){
            // const form = toMutipartedForm(data,'edit')
            const  res  = await axiosService.post(baseUrl+'/'+data.id+'?_method=PUT',data)
            message.value= 'L\'untilisateur '+ res.data.email +' a bien été modifier'
            getdata.value = res.data
        }else {
            // const form = toMutipartedForm(data)
            // const form =  (data)
            const  res  = await axiosService.post(baseUrl,data)
            getdata.value = res.data
            message.value= 'L\'untilisateur '+ res.data.email +' a bien été ajouté '

        }
        formerrors.value = {}
    }catch (e) {
        formerrors.value =  e.response.data?.errors
        message.value= 'Merci de corriger vos erreurs ! '

    }finally {
        loading.value = false
        etat.value = true
    }
}



const useSave = () => {


    return {
        loading,
        formerrors,
        etat,
        message,
        save,
        getdata
    }
}

export  default  useSave
