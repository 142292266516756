import {ref} from "@vue/reactivity";
import {axiosService} from "../services/axios.service";

const loading = ref(false)
const datas = ref([])
const errors = ref(null)

const useGetdata =  (url) =>{


    loading.value = true
    errors.value = null
    const fetchData = async ()=>{
        try {
            const  res =   await axiosService.get(url)
            datas.value = res.data
            loading.value = false
        }catch (e) {
            loading.value = false
            errors.value = e.response.data
        }
    }

    return {  datas, loading ,fetchData}
}

export  default  useGetdata