<template>
  <Breadcrumb :menu="bread"/>


  <div class="card">
    <div class="card-body">



      <div class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;"  v-if="isLoading"></b-spinner>
      </div>

        <b-overlay :show="isLoading" rounded="sm">

        </b-overlay>

<!-- description="We'll never share your email with anyone else."-->
      <div class="row">
        <div class="col-lg-6">
          <h5>Informations de l'utilisateur</h5>
          <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
            <b-form-group
                class="form-invalid"
                id="input-group-1"
                label="Email address:"
                label-for="input-1"
            >
              <b-form-input
                  :class="{'is-invalid':httperrors['email']}"
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  required
              ></b-form-input>
              <div  class="invalid-feedback">{{httperrors.email?.join(' ')}}</div>
            </b-form-group>

            <b-form-group
                id="input-group-name"
                label="Nom d'utilisateur"
                label-for="name"
            >
              <b-form-input
                  :class="{'is-invalid':httperrors['name']}"
                  id="name"
                  v-model="form.name"
                  type="text"
                  placeholder="Nom d'utilisateur"
                  required
              ></b-form-input>
              <div  class="invalid-feedback">{{httperrors.name?.join(' ')}}</div>
            </b-form-group>

            <b-form-group
                id="input-group-telephone"
                label="Téléphone d'utilisateur"
                label-for="telephone"
            >
              <b-form-input
                  :class="{'is-invalid':httperrors['telephone']}"
                  id="telephone"
                  v-model="form.telephone"
                  type="text"
                  placeholder="Téléphone d'utilisateur"
                  required
              ></b-form-input>
              <div  class="invalid-feedback">{{httperrors.telephone?.join(' ')}}</div>
            </b-form-group>

            <b-form-group id="input-group-type" label="Roles:" label-for="input-type">
              <b-form-select id="input-type"   v-model="form.type" :options="roles" required></b-form-select>
            </b-form-group>

            <div class="form-check form-switch">
              <input v-model="form.online" class="form-check-input" type="checkbox" id="flexSwitchCheckChecked">
              <label class="form-check-label" for="flexSwitchCheckChecked">
                <span>Status en ligne  {{form.online}}</span>
              </label>
            </div>


<!--            <div class="form-check">-->
<!--              <input  v-model="form.online" class="form-check-input" type="checkbox"   id="flexCheckChecked"  >-->
<!--              <label class="form-check-label" for="flexCheckChecked">Checked checkbox</label>-->
<!--            </div>-->

            <br>

            <b-button type="submit" variant="primary">
              <div class="span" v-if="form.id">
                  <span class="">
                     <i  class="fadeIn animated bx bx-pencil"></i> Modifier
                    <b-spinner  v-if="isLoading" small variant="light"></b-spinner>
                  </span>
              </div>

              <div class="span" v-else>
                 <span class="" v-if="isLoading">
                    <b-spinner small variant="light"></b-spinner>
                  </span>
                <span v-else>
                    <i class="fadeIn animated bx bx-plus-circle"></i> Ajouter
                </span>
              </div>
            </b-button>
            <b-button  v-if="!form.id" type="reset" class="mx-3" variant="danger">Reset</b-button>
          </b-form>

        </div>

        <div class="col-lg-6" v-if="form.profil">
          <h5>Informations sur le compte</h5>

          <b-form-group
              class="form-invalid"
              id="compte-nom"
              label="Nom"
              label-for="compte-nom"
          >
            <b-form-input
                :class="{'is-invalid':httperrors['nom']}"
                id="compte-nom"
                v-model="form.profil.nom"
                type="text"
                placeholder="Nom "
                required
            ></b-form-input>
            <div  class="invalid-feedback">{{httperrors.nom?.join(' ')}}</div>
          </b-form-group>


          <b-form-group
              class="form-invalid"
              id="compte-prenom"
              label="Prénom"
              label-for="compte-prenom"
          >
            <b-form-input
                :class="{'is-invalid':httperrors['prenom']}"
                id="compte-prenom"
                v-model="form.profil.prenom"
                type="text"
                placeholder="Prénom "
                required
            ></b-form-input>
            <div  class="invalid-feedback">{{httperrors.prenom?.join(' ')}}</div>
          </b-form-group>


          <b-form-group id="input-group-type" label="Sexe:" label-for="input-type">
            <b-form-select id="input-type"   v-model="form.profil.sexe" :options="optionssexe" required></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-type" label="Type de pièces:" label-for="input-type">
            <b-form-select id="input-type" v-model="form.profil.typepiece" :options="['CND','PASSEPORT','CARTE ELECTEUR']" required></b-form-select>
          </b-form-group>


          <b-form-group
              class="form-invalid"
              id="compte-numpiece"
              label="numpiece"
              label-for="compte-numpiece"
          >
            <b-form-input
                :class="{'is-invalid':httperrors['numpiece']}"
                id="compte-numpiece"
                v-model="form.profil.numpiece"
                type="text"
                placeholder="numpiece "
                required
            ></b-form-input>
            <div  class="invalid-feedback">{{httperrors.numpiece?.join(' ')}}</div>
          </b-form-group>




          <div class="">
            <Datepicker  v-model="form.profil.dateness"  />




          </div>

<!--        <pre>-->
<!--          {{form}}-->
<!--        </pre>-->

        </div>
      </div>


      <hr>

      <div class="row">
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col">
          <button class="btn btn-dark">Modifier le mot de passe</button>
        </div>
      </div>
      <hr>

     <form-change-password :usertoupdate="form"/>
    </div>
  </div>








  <!-- Button trigger modal -->
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Launch demo modal
  </button>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import Datepicker from 'vue3-date-time-picker';
import Breadcrumb from "../../components/elements/breadcrumb";
import {ref, nextTick, onMounted} from 'vue'
import {useRoute,useRouter} from "vue-router";
import {createToast} from "mosha-vue-toastify";
import useFetch from "../../composable/useFetch";
import FormChangePassword from "../../components/elements/form-change-password";
// import _ from "lodash";
const route = useRoute()
const router = useRouter()

const basUrl = ref('/admin/users');
const show = ref(true)

const optionssexe = ref([
    'Homme',
    'Femme',
])

const form = ref({
  email: '',
  name: '',
  online:false

})

// watch(()=> _.cloneDeep(form.value.online)  , (currentValue,oldF) => {
//   console.log(currentValue,oldF)
//   currentValue ?  form.value.online = 1 : form.value.online = 0
// });

const { singleData,getSingle,save ,isLoading,httperrors,message,etat}  = useFetch(basUrl.value)
const bread = {
  title:'Ajouter un utilisateur ',
  link:'/admin/users/store',
  links:[
    { title:'Utilisateurs',link:'/admin/users/index'},
    { title:'Liste des utilisateurs',link:'/admin/users/index'},
    { title:'Ajouter un utilisateur ',link:'/admin/users/store'}
  ]
}

onMounted(async ()=>{
  const  id = route.params.id
  if (id ){
    await getSingle(id)
    form.value = singleData.value
    form.value.online = singleData.value.online === 1 ? true : false
    console.log(singleData.value)
  }
})

const roles = [
    {text:"Selectionner un rôle",value:null},
    {text: 'User', value: 'user'},
    {text: 'Admin', value: 'admin'},
    {text: 'Super Admin', value: 'superadmin'},
    {text: 'Opérateur', value: 'operateur'},
]





const onSubmit =async () => {
  form.value.online = form.value.online ? 1 :  0
  await save(form.value)
  if (etat.value){
    createToast(message.value ,{type:"success",timeout:10000})
    router.push('/admin/users/index')
  }else {
    createToast(message.value ,{type:"danger"})
  }
}

const onReset = (event) => {
  event.preventDefault()
  form.value = ''

  nextTick(() => {
    show.value = true
  })
}
</script>

<style></style>
