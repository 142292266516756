<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style>
.mosha__toast.success {
  background-color: green !important;
}
</style>
