

const  state = {
    products:[]
}

const getters = {
    products : (state)=> state.products,
    itemCount : (state)=>  state.products.reduce( (total,prod)=> total+ prod.quantity,0 ) ,
    totalPrix:  (state)=>   state.products.reduce((total,prod)=> total + (prod.quantity*parseInt(prod.product.prix)),0)

}

const actions = {
    GET_USER( { commit },user ){
        commit('SET_USER',user)
    },
}
const  mutations = {
    ADD_PRODUCT (state,product){

        let line = state.products.find(p =>p.product.id === product.id)

        if (line !== undefined){
            line.quantity++
        }else {
            state.products.push({product:product,quantity:1})
        }
    },

    SET_CHANGE_QUANTITY(state,update){
        if ( update.quantity===0 ){
            this.commit('cart/REMOVEPRODUCT',update)
        }
        update.product.quantity = update.quantity
    },
    REMOVEPRODUCT(state, lineToRemove) {
        let index = state.products.findIndex(line => line === lineToRemove);
        if (index > -1) {
            state.products.splice(index, 1);
        }
    },
    VIDER_CART(state){
        state.products = []
    },
    SET_INT_PRODUCT (state,products){
        state.products =(products)
    }
}



export default {
    strict: true,
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
