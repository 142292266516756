<template>
  <!--breadcrumb-->
  <Breadcrumb :menu="bread"/>
  <!--end breadcrumb-->
<!--  <h6 class="mb-0 text-uppercase">Liste des utilisateurs</h6>-->
<!--  <hr/>-->
  <div class="card">
    <div class="card-body">

      <div class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;"  v-if="isLoading"></b-spinner>
      </div>

      <div class="table-responsive" >
      <h2>Liste des utilisateurs</h2>

          <input type="search" name="" class="form-control" id="" v-model="q" >
          <hr>
        <h3>
          {{q }}
        </h3>
        <b-overlay :show="isLoading" rounded="sm">
        <table id="example2" class="table table-striped table-bordered">
          <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Nom</th>
            <th>Etat</th>
            <th>Rôle </th>
<!--            <th>Photos </th>-->
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>


          <tr  v-for="user in lists.data" :key="user.id">
            <td>{{user.id}}</td>
            <td>{{user.email}}</td>
            <td>{{user.telephone}}</td>
            <td>{{user.nom + ' '+user.prenom }}</td>
            <td>
              <isonline :online="user.online" :key="user.id"/>
            </td>
            <td>{{user.type}} </td>

<!--            <td>{{user.img}}</td>-->
            <td>
              <div class="d-flex order-actions">
                <a
                    href="#"
                    @click.prevent="deleteUser(user.id)"
                    class="text-danger bg-light-danger border-0"
                ><i class="bx bxs-trash"></i
                ></a>
                <router-link :to="{name:'EditUsers',params:{id:user.id}}"
                    class="ms-4 text-primary bg-light-primary border-0"
                ><i class="bx bxs-edit"></i
                ></router-link>

                <router-link :to="{name:'ShowUsers',params:{id:user.id}}"
                             class="ms-4 text-primary bg-light-primary border-0"
                ><i class="bx bxs-user"></i
                ></router-link>
              </div>
            </td>
          </tr>

          </tbody>
          <tfoot>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Nom</th>
            <th>Etat</th>
            <th>Rôle </th>
            <th>Actions</th>
          </tr>
          </tfoot>
        </table>
        </b-overlay>
      </div>


      <nav aria-label="Page navigation example">
        <ul class="pagination">
<!--          {{users.meta}}-->
          <li class="page-item" :class="{'active':p.active}" v-for="(p  , index) in lists.meta?.links" :key="index" @click.prevent="goTo(p.url)">
            <button class="page-link"  v-html="p.label" ></button>
          </li>

        </ul>
      </nav>


    </div>
  </div>



</template>

<script setup>


import useFetch from "../../composable/useFetch";
import {onMounted} from "vue";
import {ref} from "@vue/reactivity";
import Breadcrumb from "../../components/elements/breadcrumb";
import isonline from "../../components/elements/isonline";


const basUrl = ref('/admin/users');
const {lists , getResultsLink,isLoading , q,deleteModel}  = useFetch(basUrl.value)

const bread = {
  title:'Ajouter un utilisateur ',
  link:'/admin/users/store',
  links:[
    { title:'Utilisateurs',link:'/admin/users/index'},
    { title:'Liste des utilisateurs',link:'/admin/users/index'}
  ]
}
const goTo = (url) => {
    basUrl.basUrl = url
    getResultsLink( basUrl.basUrl)
}
onMounted(() => {
  getResultsLink()
})

const deleteUser = (id)=>{
  const rep = confirm("Supprimer l'enregistrement ?")
  rep? deleteModel(id) : null

  return   getResultsLink()


}

</script>

<style></style>
