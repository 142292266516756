<template>
  <div class="card">
    <div class="card-body">
      <div class="border p-4 rounded">
        <div class="text-center">
<!--          <pre>-->
<!--            {{ errors.email }}-->
<!--            {{ isLoading }}-->
<!--          </pre>-->
          <h3 class="">Connexion</h3>
          <!-- <p>
            Don't have an account yet?

            <a href="authentication-signup.html">Inscrivez-vous ici</a>
          </p> -->
        </div>
        <!-- <div class="d-grid">
          <a class="btn my-4 shadow-sm btn-white" href="javascript:;">
            <span class="d-flex justify-content-center align-items-center">
              <img
                        class="me-2"
                        src="assets/images/icons/search.svg"
                        width="16"
                        alt="Image Description"
                      />
              <span>Connexion with Google</span>
            </span>
          </a>
          <a href="javascript:;" class="btn btn-facebook"
            ><i class="bx bxl-facebook"></i>Connexion with Facebook</a
          >
        </div> -->
        <div class="login-separater text-center mb-4">
          <span>OU CONNECTEZ-VOUS AVEC EMAIL</span>
          <hr />
        </div>
        <div class="form-body">
          <form class="row g-3" @submit.prevent="connexion">
            <div class="col-12">
              <label for="inputEmailAddress" class="form-label"
                >Email</label
              >
              <input
                  :class="{' is-invalid':errors.email }"
                v-model="form.email"
                type="text"
                class="form-control"
                id="inputEmailAddress"
                placeholder="Email"
              />
              <span class="text-danger" v-if="errors && errors.email " v-html="errors.email"> </span>



            </div>
            <div class="col-12">
              <label for="inputChoosePassword" class="form-label"
                >Mot de passe</label
              >
              <div class="input-group" id="show_hide_password">
                <input
                  :class="{' is-invalid':errors.password }"
                  v-model="form.password"
                  type="password"
                  class="form-control border-end-0"
                  id="inputChoosePassword"
                  placeholder="Mot de passe"
                />
                <a href="javascript:;" class="input-group-text bg-transparent"
                  ><i class="bx bx-hide"></i
                ></a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-check form-switch">
<!--                <input-->
<!--                  v-model="form.remember"-->
<!--                  class="form-check-input"-->
<!--                  type="checkbox"-->
<!--                  id="flexSwitchCheckChecked"-->
<!--                />-->
<!--                <label class="form-check-label" for="flexSwitchCheckChecked"-->
<!--                  >Souviens-toi de moi</label-->
<!--                >-->
              </div>
            </div>
            <div class="col-md-6 text-end">
<!--              <a href="#">Mot de passe oublié ?</a>-->
            </div>
            <div class="col-12">
              <div class="d-grid">
                <button type="submit" class="btn btn-primary">
                  <span class="" v-if="isLoading">
                    <b-spinner small variant="light"></b-spinner>
                  </span>
                  <span class="" v-else>
                   CONNEXION
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import {useStore} from "vuex";
import {onMounted} from "vue";
import router from "../../router";
import {axiosService} from "../../services/axios.service";
import {createToast} from "mosha-vue-toastify";
const store = useStore()


// email: "gastinoking@gmail.com",
    // password: "admin123",
const form = ref({
  email: "",
  password: "",
  remember: false,
});
const isLoading =  ref(false)
const errors = ref({})

onMounted(()=>{
  if (store.state.auth.status.loggedIn){
    router.push('/admin/dashboard')
  }
})
const connexion = async () => {
  isLoading.value = true

      try {
        const rep = await axiosService.post('/admin-login', form.value)
        isLoading.value = false
        createToast('Vous êtes maintenent connecté !',{type:"success"})
        if (rep.data.user)
        {
          localStorage.setItem('user_novissi',JSON.stringify(rep.data.user))
          store.commit('auth/loginSuccess', rep.data.user);
        }
        window.location.href='/admin/dashboard'
        // await router.push('/admin/dashboard')
      }catch (error) {
        console.log(error)
        isLoading.value = false
        let er={}
        for (const errorsKey in error.response.data.errors) {
          er[errorsKey] = error.response.data.errors[errorsKey].join(' <br>')
        }
        errors.value = er
        store.commit('auth/loginFailure');
        createToast('Login ou mot de passe invalide',{type:"danger"})

      }

  // await AuthService.login(form.value);
  // await  store.dispatch('user/GET_USER',await AuthService.currentUser)

  // await store.dispatch('GET_USER',await AuthService.currentUser)
  // const currentuser = await store.getters['user/currentuser']
};


</script>

<style></style>
