<template>
  <div class="row">
    <div class="col">
      <div class="card radius-10 mb-0">
        <div class="card-body">
          <div class="d-flex align-items-center">


<!--            <div class="ms-auto">-->
<!--              <a href="javscript:;" class="btn btn-primary btn-sm radius-30"-->
<!--              >View All Products</a-->
<!--              >-->
<!--            </div>-->
          </div>

          <div class="row py-4">
<!--            <div class="col">-->
<!--              <h5>Titre</h5>-->
<!--            </div>-->
            <div class="col">
              <label for="recherche">Colonnes</label>
              <b-form-select class="form-control"  :options="columns" v-model="query.search_column"></b-form-select>
            </div>
            <div class="col">
              <label for="recherche">Oprérateurs</label>
              <b-form-select class="form-control"  :options="operators" v-model="query.search_operator"></b-form-select>
            </div>
            <div class="col">
              <label for="recherche">Rechercher</label>
              <input type="search"  id="recherche"  class="form-control"  @keyup.enter="loadData()"  placeholder="Rechercher..."  v-model="query.search_input"/>
            </div>

            <div class="col">
              <br>
              <button class="btn btn-primary "  @click.prevent="loadData()" >Rechercher </button>
            </div>

          </div>

          <div class="">
            <div class="">
              <input type="search" class="form-control" placeholder="Rechercher..."  v-model="q"/>
            </div>
          </div>


          <div class="table-responsive mt-3">
            <table class="table align-middle mb-0">
              <thead class="table-light">
              <tr>
                <th class="pointer-event" v-for="(th,index ) in columns" :key="index"  @click="toggleOrder(th)">

                  {{th}}
                  <template class="" v-if="th===query.column" >
                    <span v-if="query.direction ==='asc'">&darr;</span>
                    <span v-else>&uarr;</span>
                  </template>


                </th>
                <th>Status</th>
                <th>Actions</th>

              </tr>
              </thead>


              <tbody>

              <tr v-for="profil in lists" :key="profil.id">
<!--                <td v-for="(th,index ) in profil" :key="index" >{{th}}</td>-->
                <td v-for="(th,index ) in columns" :key="index" >{{profil[th]}}</td>
                <td>
                  <b-badge :variant="`${dejaOpere(profil.user.operations) ? 'success':'danger' }`">
                    {{dejaOpere(profil.user.operations) ? 'déjà' :  'Pas encore'}}
                  </b-badge>
                </td>
                <td >
                  <div class="d-flex order-actions" >
                    <button @click.prevent="goTopage(props.editerid,profil.user.id)"  class="btn  btn-sm" :class="`${dejaOpere(profil.user.operations) ? 'btn-success':'btn-danger' }`">Opération  </button>
                  </div>
                </td>
              </tr>

              </tbody>


            </table>
          </div>
        </div>
      </div>
    </div>


  </div>
  <hr>
  <div class="bg-white p-2">
    <div class="d-flex justify-content-between">
      <div class="">
        <input class="form-control"  type="number" @keyup.enter="loadData()" v-model="query.per_page"   id="">
      </div>

      <div class="">
        <br>
        Afichage de {{profils?.from}} à {{profils?.to}} sur {{profils.total}} Enregistrements
      </div>

      <div class="">

        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <!--          {{users.meta}}-->
            <li class="page-item" :class="{'active':p.active}" v-for="(p  , index) in profils?.links" :key="index" @click.prevent="goTo(p.label)">
              <button class="page-link"  v-html="p.label" ></button>
            </li>

          </ul>
        </nav>
      </div>

    </div>
  </div>




<!--<pre>-->
<!--  {{ lists }}-->
<!--</pre>-->

</template>

<script setup>
import {computed, defineProps, onMounted, watch} from 'vue'
import {axiosService} from "../../../services/axios.service";
import {ref} from "@vue/reactivity";
import router from "../../../router";

const profils = ref({})
const loading = ref({})
const q = ref('')
const columns = ref([])
const operators = ref([])

const query = ref({
    page:1,
    column: 'id',
    direction:'asc',
    per_page:10,
    search_operator:'equal',
    search_input:'',
    search_column:'id',


})
// const basUrl = ref(null)

  const props = defineProps({
    editerid:{
      type:[Number,String],
      default:0,

    },

  })
// const listsHeaders = computed(()=>{
//   var h= null
//   if (lists.value){
//     h = Object.keys(lists.value[0] )
//   }
//   return h
// })

// const defaultelistsHeaders = computed(()=>{
//
//   return ['id','fullname','sexe','typepiece','numpiece','Actions']
// })


const lists = computed(()=>profils.value.data)
// const meta = computed(()=>profils.value.meta)


  const loadData = async  ()=>{
    // page=${query.value.page}&column=${query.value.column}&direction=${query.value.direction}&per_page=${query.value.per_page}

    let url_params=   ''
    for (const urlParam of  Object.keys(query.value)) {
      url_params += urlParam  +'='+query.value[urlParam]+'&'
    }
    url_params = url_params.substring(0, url_params.length - 1)
    url_params= url_params+'&q='+q.value
    loading.value = true
    try {
      const res = await  axiosService.get(`/admin/novissis/${props.editerid}/profils?${url_params}`)
      profils.value = res.data.model
      columns.value = res.data.columns

      operators.value =Object.keys(res.data.operators).map(el=>{
        let info = {value:el,text:res.data.operators[el]}
        return info
      })
      loading.value = false

    }catch (e) {
      console.log(e)
      loading.value = false
    }
  }
  onMounted(()=>{
    loadData()
  })


watch(q, ()=>{
  // query.value = {...query.value,...q.value}

  loadData()
})

const toggleOrder = (column)=>{
  if (query.value.column === column){
    query.value.direction ==='desc' ? query.value.direction='asc' :query.value.direction= 'desc'
  }else {
    query.value.column = column
    query.value.direction ='asc'
  }

  loadData()
}

const dejaOpere = (operations) => {
  const datas = operations.map(op=>{
    return { user_id:op.user_id,editionnovissi_id:op.editionnovissi_id}
  }).find(ele=>ele.editionnovissi_id==props.editerid)
  return !!datas
}

const goTo = (page) => {
  // alert(url)
  query.value.page = page
  loadData()

}
const goTopage = (idnovissi,userid)=>{
   router.push({name:'ProfileAction',params:{id:idnovissi,userid:userid}})

}
</script>

<style scoped>

</style>
