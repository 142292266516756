<template>

  <form  @submit.prevent="onSubmit" :validation-schema="schemaBoutique" >

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Ancien mot de passe </h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input
            v-model="form.oldpassword"
            autocomplete="off"
            name="name"
            placeholder="Ancien mot de passe "
            type="text" class="form-control"
            @blur="validate('oldpassword')"
            @keypress="validate('oldpassword')"
            :class="{'is-invalid':errors['oldpassword']}"
        />
        <!--        <ErrorMessage name="name" class="error-feedback" />-->
        <span class="text-danger" v-if="!!errors.oldpassword">{{errors.oldpassword}}</span>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Nouveau mot de passe   </h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input v-model="form.password"  @blur="validate('password')"
               :class="{'is-invalid':errors['password']}"
               @keypress="validate('password')" name="password"   placeholder="Nouveau mot de passe   " type="text" class="form-control"/>
        <span class="text-danger" v-if="!!errors.password">{{errors.password}}</span>
      </div>
    </div>


    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Confirmer mot de passe    </h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input v-model="form.password_confirmation"   @blur="validate('password_confirmation')"
               :class="{'is-invalid':errors['password_confirmation']}"
               @keypress="validate('password_confirmation')" name="password_confirmation"   placeholder="Confirmer mot de passe" type="text" class="form-control"/>
        <span class="text-danger" v-if="!!errors.password_confirmation">{{errors.password_confirmation}}</span>
      </div>
    </div>



    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-9 text-secondary">

        <button type="submit"  class="btn btn-primary px-4" :class="{'btn-success':form.id}">
          <b-spinner small v-if="loading" />

          <span>Modifier le mot de passe</span>
        </button>
        <button class="btn btn-danger mx-4" @click.prevent="reset"> Effacer </button>
      </div>
    </div>
  </form>

<pre>
  {{form}}
</pre>

</template>

<script setup>

import {ref} from "@vue/reactivity";
import {createToast} from "mosha-vue-toastify";
import {computed, defineEmits, defineProps, onMounted, watch,} from "vue";
import * as yup from "yup";
import useSave from "../../composable/useSave";



const props = defineProps({
  usertoupdate:Object
})

const schemaBoutique = yup.object().shape({
  oldpassword:yup.string()
      .min(3, "Doit contenir au moins 3 caractères !")
      .max(100, "L' ancien mot de passe  ne doit pas dépasser 100 caractères")
      .required("L' ancien mot de passe  est requis"),
  password:yup.string()
      .required('Le mot de passe est requise')
  // .typeError('Vous devez spécifier un nombre')
  // .max(11, "Le téléphonene doit pas dépassé 11 characters")
  // .min(7, "Must be at least 7 characters!"),
  ,
  password_confirmation:yup.string()
      .required('Confirmer mot de passe est requise')
      .oneOf([yup.ref('password'), null], 'Les mot de passe ne sont pas conforment ')
})

const itemToUpdate = computed(()=>props.usertoupdate)
const emits = defineEmits(['add-boutique'])
const baseUrl = '/admin/users-change-password'

const form = ref(
    {
      id: itemToUpdate.value.id,
      oldpassword:'admin123',
      password:'admin1234',
      password_confirmation:'admin1234',
    }
)

const  errors = ref({
  oldpassword:'',
  password:'',
  password_confirmation:'',
})

watch(itemToUpdate,(value)=>{
  form.value =  {
    id: value.id,
    oldpassword:'admin123',
    password:'admin1234',
    password_confirmation:'admin1234',
  }
})

onMounted(()=>{

})
const  {loading,etat,message,save , getdata }   =  useSave()

const validate = (field) =>{
       schemaBoutique.validateAt(field, form.value)
      .then( () => (errors.value[field] = "") )
      .catch((err) => {
        console.log(err.path, err.message)
        errors.value[err.path] = err.message;
      });
}

const onSubmit =   ()=>{
  schemaBoutique.validate(form.value, { abortEarly: false })
      .then(  () => {
        // alert('coool')
        saveData()
      })
      .catch((err) => {
        console.log(err)
        err.inner.forEach((error) => {
          errors.value = { ...errors.value, [error.path]: error.message };
        });
      });
}
const saveData = async ()=>{
  await save(baseUrl,form.value)
  if (etat.value){
    // form.value = {}
    createToast(message.value ,{type:"success",timeout:10000})
    emits('add-boutique',getdata.value)
  }else {
    createToast(message.value ,{type:"danger"})
  }

}
const reset = () =>{

  errors.value = {
    id:props.usertoupdate.id,
    oldpassword:'',
    password:'',
    password_confirmation:'',
  }
  form.value = {
    id:props.usertoupdate.id,
    oldpassword:'',
    password:'',
    password_confirmation:'',
  }
}


</script>

<style scoped>

</style>

