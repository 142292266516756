const state = {
    utilisateur: null
};


const actions= {
    GET_USER( { commit },user ){
        commit('SET_USER',user)
    }
};
    const mutations = {
    SET_USER (state,payload){
        state.utilisateur = payload
    }
};


export default {
    namespaced: true,
    state,
    mutations,
    actions
}