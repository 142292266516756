<template>
<!--  {{uploadPercentage}}-->
  <b-progress :value="uploadPercentage" v-if="!isLoading"></b-progress>
  <input    type="file" name="" id="" class="form-control " :class="{'is-invalid':hasError}"  @change="handleFileUploads( $event )" />
  <div class="text-danger" v-if="hasError" >
      Attention
  </div>
</template>

<script setup>

import {ref, } from "vue";
import {axiosService} from "../../services/axios.service";
import { useStore } from 'vuex'
import {createToast} from "mosha-vue-toastify";

const store = useStore()
//vars
const file = ref('')
const hasError = ref(false)
const isLoading = ref(false)
const uploadPercentage = ref(0)


const handleFileUploads =async ($event) =>{
    const uploadFile = $event.target.files[0];

    if (uploadFile.type !== 'text/csv'){
      hasError.value = true
     return  createToast("Le fichier n'est pas valide",{type:"danger",'position':'bottom-right'})
    }

    uploadPercentage.value = 0
    file.value =uploadFile
    let formData = new FormData();
    formData.append('file', file.value);
    store.commit('citoyens/SET_FILECSV',formData)
    store.commit('citoyens/SET_ISFILELOADING',true)
    try {
      isLoading.value = true
     const res = await axiosService.post('/admin/uploadcsv',formData,{
       onUploadProgress:function (progressEvent) {
         uploadPercentage.value = parseInt(Math.round((progressEvent.loaded/progressEvent.total) *100 ))
       }.bind(this)
     })
     await  store.dispatch('citoyens/GET_CITOYENS',res.data)
      store.commit('citoyens/SET_ISFILELOADING',false)
      createToast('Le fichier a bien été uploader avec succès ',{type:"success",'position':'bottom-right'})
    }catch (e) {
      console.log(e.response.data)
      console.log('FAILURE!!');
        hasError.value = true
      store.commit('citoyens/SET_ISFILELOADING',false)
    }finally {
      isLoading.value = false
    }
}
</script>

<style scoped>

</style>
