



<template>
  <Breadcrumb :menu="bread"/>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8">

              <form-etats @addBoutique="addProduct"  :updateItem="updateItem"/>

            </div>
          </div>


        </div>


      </div>
      <div class="row">

        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <b-spinner variant="primary"  v-if="loading" />
              <list-etats v-else @updateBoutique="updateBoutique" @deleteProduct="deleteProduct" :datas="datas" baseUrl="/admin/boutiques"/>
            </div>
          </div>
        </div>

        <div class="">
<!--        <pre>-->
<!--            {{datas}}-->
<!--        </pre>-->
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import Breadcrumb from "../../components/elements/breadcrumb";
import { onMounted} from "vue";
import useGetdata from "../../composable/useGetdata"
import {ref} from "@vue/reactivity";

import ListEtats from "../../components/elements/etats/listEtats";
import FormEtats from "../../components/elements/etats/formEtats";

const baseUrl =  ('/admin/etats')

const updateItem = ref({})


const bread = {
  title:null,
  link:'/admin/etats/store',
  links:[
    { title:'Etats',link:'/admin/etats/index'},
    { title:'Liste des Etats',link:'/admin/etats/index'}
  ]
}




const { loading ,datas , fetchData} =  useGetdata(baseUrl)

const addProduct = (data)=>{
  if (!data.data.id)
    datas.value.data.unshift(data.data)
  fetchData()
}
const deleteProduct = ()=>{
  fetchData()
  // alert('fdfff')
}
const updateBoutique = (prod)=>{
  updateItem.value = prod
}

onMounted(  ()=>{
  fetchData()
})

</script>

<style scoped>

</style>

