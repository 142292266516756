<template>
  <Breadcrumb  :menu="bread"/>

  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;"  v-if="isLoading"></b-spinner>
      </div>
      <b-overlay :show="isLoading" rounded="sm">
      </b-overlay>

      <!-- description="We'll never share your email with anyone else."-->
      <div class="row">
        <div class="col-lg-6">

        <div @click="onSubmit" class=""></div>




        </div>

        <div class="col-lg-6">

          <div class="">
             {{isfileLoading}}
          </div>

        <div class="" v-if="form.id">

          <h5>Charger le fichier des bénéficiaire</h5>
          <div  v-if="!citoyens">
            <UploadFile/>
          </div>

          <div class="" v-else>
           <div class="d-flex justify-content-between">
             <button @click.prevent="onGenerate" class="btn btn-danger btn-block ">Générer les comptes pour {{form.name}}</button>
             <button @click.prevent="close" class="btn btn-dark   ">Fermer <i class="fas fa-close"></i></button>
           </div>

            <div class="">
              <hr>
              <h4>Total : {{citoyens[0].length}}</h4>
            </div>
            <hr>
            <b-spinner variant="danger" v-if="loadingData" />
          </div>

        </div>

        </div>
        <h3>Liste des </h3>
        <b-progress variant="success" :value="uploadPercentage"></b-progress>
        <PreviewListCitoyen v-if="citoyens?.length"  :data="citoyens"  />

      </div>



    </div>
  </div>





</template>

<script setup>

import 'vue3-date-time-picker/dist/main.css'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const router = useRouter();
const bread = {
  title:'Ajouter une éditions de novissi ',
  link:'/admin/editionnovissi/store',
  links:[
    { title:'Edition de novissi',link:'/admin/editionnovissi/index'},
    { title:'Liste des éditions novissi',link:'/admin/editionnovissi/index'},
    { title:'Ajouter une éditions de novissi ',link:'/admin/editionnovissi/store'}
  ]
}

import Breadcrumb from "../../components/elements/breadcrumb";
import {ref, onMounted, computed} from 'vue'
import {useRoute} from "vue-router";
import {createToast} from "mosha-vue-toastify";
import useFetch from "../../composable/useFetch";
import UploadFile from "../../components/elements/UploadFile";
import PreviewListCitoyen from "../../components/elements/previewListCitoyen";
import {axiosService} from "../../services/axios.service";
// import tikets from "../../store/tikets";
// import _ from "lodash";
const route = useRoute()
const store = useStore()

const basUrl = ref('/admin/editionnovissi');
const basUrlTickets = ref('/admin/tickets');

const loadingData = ref(false)
const tickets = ref([])


const uploadPercentage = ref(0)

const citoyens= computed(()=>store.getters['citoyens/citoyens'] || null)
const formData = computed(()=>store.getters['citoyens/filecsv'] || null)
const isfileLoading = computed(()=>store.getters['citoyens/isFileLoading'] || null)


const form = ref({
  name: '',
  datedeb: new Date() ,
  datefin: new Date(),
  montant: '',
  ticket_id: 0,
})

const getTickets = async ()=>{
  try {
    const res = await axiosService.get(basUrlTickets.value)
    tickets.value = res.data.data
  }catch (e) {
    console.log(e)
  }
}


const close = ()=>{
    store.dispatch('citoyens/GET_CITOYENS',null)
}
const onGenerate = async ()=>{
  loadingData.value = true
 try {
   formData.value.append('novissi_id', route.params.id);
   const rep = await  axiosService.post('/admin/generate',formData.value,{
     onUploadProgress:function (progressEvent) {
       uploadPercentage.value = parseInt(Math.round((progressEvent.loaded/progressEvent.total) *100 ))
     }.bind(this)
   })
   loadingData.value = false
   console.log(rep.data)
   }catch (e) {
   loadingData.value = false
   console.log(e)
   }
}

// watch(()=> _.cloneDeep(form.value.online)  , (currentValue,oldF) => {
//   console.log(currentValue,oldF)
//   currentValue ?  form.value.online = 1 : form.value.online = 0
// });

const { singleData,getSingle,save ,isLoading,message,etat}  = useFetch(basUrl.value)

onMounted(async ()=>{
  const  id = route.params.id
  if (id ){
    await getSingle(id)
    form.value = singleData.value
    form.value.online = singleData.value.online === 1 ? true : false
    form.value.ticket_id = singleData.value.ticker.id
    form.value.montant = singleData.value.ticker.total
    console.log(singleData.value)
  }

  getTickets()

  // resolv(3,-2,-5)
      // = 3x² - 2x - 5 = 0

})



const onSubmit =async () => {


  form.value.online = form.value.online ? 1 :  0
  form.value.datedeb =convertDateToTime(form.value.datedeb)
  form.value.datefin = convertDateToTime(form.value.datefin)

  await save(form.value)
  if (etat.value){
    createToast(message.value ,{type:"success",timeout:10000})
    router.push('/admin/editionnovissi/index')
  }else {
    createToast(message.value ,{type:"danger"})
  }
}

const convertDateToTime = (date) =>{
  const today = new Date(date);
  return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

}

</script>

<style scoped>

</style>
