<template>
  <div
    class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0 bgauth"

  >
    <div class="container-fluid">
      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
        <div class="col mx-auto">
          <div class="mb-4 text-center">
            <!-- <img src="assets/images/logo-img.png" width="180" alt="" /> -->
          </div>

          <router-view></router-view>
        </div>
      </div>
      <!--end row-->
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import { useStore } from 'vuex'
import { useRoute,useRouter } from'vue-router'
const route = useRoute();
const router = useRouter();

const store = useStore()
const currentuser = computed(()=> store.state.auth.user?.user || {})
 onMounted(()=> {

 if (route.path==='/' &&  currentuser.value.id){
   router.push('/admin/dashboard')
 }else{
     router.push('/login')
 }

 })
</script>

<style scoped>
.bgauth{
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1541704199033-f10724b07aef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
}
</style>
