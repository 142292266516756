<template>

  <form  @submit.prevent="onSubmit" :validation-schema="schemaBoutique" >

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Désignation de l'état </h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input
            v-model="form.name"
            autocomplete="off"
            name="name"
            placeholder="Désignation de l'état "
            type="text" class="form-control"
            @blur="validate('name')"
            @keypress="validate('name')"
            :class="{'is-invalid':errors['name']}"
        />
<!--        <ErrorMessage name="name" class="error-feedback" />-->
        <span class="text-danger" v-if="!!errors.name">{{errors.name}}</span>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Couleur  </h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input v-model="form.color"  @blur="validate('color')"
               :class="{'is-invalid':errors['color']}"
               @keypress="validate('color')" name="color"   placeholder="Couleur" type="text" class="form-control"/>
        <span class="text-danger" v-if="!!errors.color">{{errors.color}}</span>
      </div>
    </div>


    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Position </h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input v-model="form.position"   @blur="validate('position')"
               :class="{'is-invalid':errors['position']}"
               @keypress="validate('position')" name="position"   placeholder="Position" type="text" class="form-control"/>
        <span class="text-danger" v-if="!!errors.position">{{errors.position}}</span>
      </div>
    </div>



    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-9 text-secondary">

        <button type="submit"  class="btn btn-primary px-4" :class="{'btn-success':form.id}">
          <b-spinner small v-if="loading" />
          <span v-if="form.id">Modifier la boutique</span>
          <span v-else>Ajouter la boutique </span>
        </button>
        <button class="btn btn-danger mx-4" @click.prevent="reset"> Effacer </button>
      </div>
    </div>
  </form>

</template>

<script setup>

import {ref} from "@vue/reactivity";
import useSave from "../../../composable/useSave";
import {createToast} from "mosha-vue-toastify";
import {computed, defineEmits, defineProps, watch} from "vue";
import * as yup from "yup";



const props = defineProps({
  updateItem:Object
})

const schemaBoutique = yup.object().shape({
  name:yup.string()
      .min(3, "Doit contenir au moins 3 caractères !")
      .max(100, "Le nom ne doit pas dépasser 100 caractères")
      .required('Le nom est requis'),

  color:yup.string()
      .required('La couleur  est requise')
      // .typeError('Vous devez spécifier un nombre')
      // .max(11, "Le téléphonene doit pas dépassé 11 characters")
      // .min(7, "Must be at least 7 characters!"),
  ,
  position:yup.number()
      .required('La position est requise')
      .typeError('Vous devez spécifier un nombre')
})



const itemToUpdate = computed(()=>props.updateItem)
const emits = defineEmits(['add-boutique'])
const baseUrl = '/admin/etats'
const form = ref({
  id:null,
  name:'',
  color:'',
  slug:'',
  position:'',
})

const  errors = ref({
  id:null,
  name:'',
  color:'',
  slug:'',
  position:'',
})

watch(itemToUpdate,(value)=>{
  form.value = value
})

const  {loading,etat,message,save , getdata }   =  useSave()

const validate = (field) =>{
      schemaBoutique.validateAt(field, form.value)
      .then( () => (errors.value[field] = "") )
      .catch((err) => {
        console.log(err.path, err.message)
        errors.value[err.path] = err.message;
      });
}



const onSubmit =   ()=>{


  schemaBoutique.validate(form.value, { abortEarly: false })
      .then(  () => {
       // alert('coool')
        saveData()
      })
      .catch((err) => {

        console.log(err)
        err.inner.forEach((error) => {
          errors.value = { ...errors.value, [error.path]: error.message };
        });
      });



}


const saveData = async ()=>{

    await save(baseUrl,form.value)
    if (etat.value){
      // form.value = {}
      createToast(message.value ,{type:"success",timeout:10000})
      emits('add-boutique',getdata.value)
    }else {
      createToast(message.value ,{type:"danger"})
    }

}
const reset = () =>{
  errors.value = {
    name:'',
    telephone:'',
    adresse:'',
  }
  form.value = {
    id:null,
    name:'',
    telephone:'',
    adresse:'',
  }
}


</script>

<style scoped>

</style>
