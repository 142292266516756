<template>
  <!--breadcrumb-->
    <Breadcrumb :menu="bread"/>
  <!--end breadcrumb-->
  <!--  <h6 class="mb-0 text-uppercase">Liste des utilisateurs</h6>-->
  <!--  <hr/>-->
  <div class="card">
    <div class="card-body">

      <div class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;"  v-if="isLoading"></b-spinner>
      </div>

      <div class="table-responsive" >
        <h2>Liste des des bénéficiaires</h2>

        <input type="text" name="" class="form-control" id="" v-model="q" >
        <hr>
        <h3>
          {{q }}
        </h3>
<!--        <div class=""  v-for="user in users.data"  :key="user.id"> {{user.id}}</div>-->
        <b-overlay :show="isLoading" rounded="sm">
          <table id="example2" class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Id</th>
              <th>Nom Prenom</th>
              <th>Téléphone</th>
              <th>N pièce</th>
              <th>Montant</th>
              <th>Rôle </th>
              <th>Photos </th>
              <th>Actions</th>
            </tr>
            </thead>

            <tbody>
            <tr  v-for="user in lists.data"  :key="user.id">
              <td> {{user.id}} </td>
              <td>{{user.profil.nom}}</td>
              <td>{{user.profil.telephone}}</td>
              <td>{{user.profil.numpiece }}</td>
              <td>{{user.compte.montant }}</td>

              <td>{{user.type}} </td>

              <td>{{user.image}}</td>
              <td>
                <div class="d-flex order-actions">
                  {{route.params}}
                  <router-link :to="{name:'ShowUsers',params:{id:user.id}}"  class="ms-4 text-primary bg-light-primary border-0" ><i class="bx bxs-edit"></i></router-link>
                </div>
              </td>


            </tr>

            </tbody>
            <tfoot>
            <tr>
              <th>Id</th>
              <th>Email</th>
              <th>Téléphone</th>
              <th>Nom</th>
              <th>Etat</th>
              <th>Rôle </th>
              <th>Actions</th>
            </tr>
            </tfoot>
          </table>
        </b-overlay>
      </div>


      <nav aria-label="Page navigation">
        <ul class="pagination">
          <!--          {{users.meta}}-->
          <li class="page-item" :class="{'active':p.active}" v-for="(p  , index) in lists.meta?.links" :key="index" @click.prevent="goTo(p.url)">
            <button class="page-link"  v-html="p.label" ></button>
          </li>

        </ul>
      </nav>




    </div>
  </div>



  <pre>
    {{users}}
  </pre>

</template>

<script setup>



import {onMounted} from "vue";
import {ref} from "@vue/reactivity";
import Breadcrumb from "../../components/elements/breadcrumb";

import { useRoute } from 'vue-router'
import {axiosService} from "../../services/axios.service";
const route = useRoute()

const basUrl = ref('');
const lists = ref('');
const isLoading = ref(false);
const q = ref('');
const users = ref('');




const bread = {
  title:'Liste des bénéficiaires',
  link:'/admin/beneficiaires/index',
  links:[
    { title:'bénéficiaires',link:'/admin/beneficiaires/index'},
    { title:'Liste des bénéficiaires',link:'/admin/beneficiaires/index'},
    { title:'Liste des bénéficiaires',link:'/admin/beneficiaires/index'}
  ]
}


const goTo = (url) => {
  basUrl.basUrl = url
  loadData( basUrl.basUrl)
}

const loadData = async (url)=>{
  try {
  const rep = await axiosService.get(url)
  lists.value = rep.data
  }catch (e) {
    console.log(e)
  }
}

onMounted(() => {
  const url = '/admin/beneficiaires/'
  loadData(url)
})


</script>

<style></style>
