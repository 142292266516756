<template>
  <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">
      <router-link  to="/admin/dashboard">Accueil</router-link>
    </div>
    <div class="ps-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0 p-0">
          <li class="breadcrumb-item">
            <router-link to="/admin/dashboard">
              <i class="bx bx-home-alt"></i>
            </router-link>
          </li>
          <li v-for="(ele,index) in menu.links" :key="index" class="breadcrumb-item active" aria-current="page">
           <router-link :to="ele.link">
             {{ele.title}}
           </router-link>
          </li>

        </ol>
      </nav>
    </div>
    <div class="ms-auto" v-if="menu.title">
      <router-link :to="menu.link" class="btn btn-primary"> {{menu.title}}  <i class="bx bxs-plus-circle"></i></router-link>
    </div>
  </div>
</template>

<script>
    export default {
      props:{
        menu:{
          type:Object,
          default:()=>{}
        }
      },
      setup( ){
        // console.log(props.menu)
      }
    }
</script>
