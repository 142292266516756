<template>
  <div  class="mt-3" v-if="lines.length">


      <table class="table mb-0 table-dark table-striped">
        <thead class="table-success">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nom</th>
          <th scope="col">Qte</th>
          <th scope="col">Prix</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(prod , index) in lines" :key="index">
          <th >{{prod.product.id}}</th>
          <td >{{prod.product.name}}</td>
          <td> <strong>{{prod.quantity}}</strong></td>
          <td> <strong>{{prod.quantity*prod.product.prix }}</strong></td>
          <td>
            <button @click.prevent="Diminuer(prod)" class="btn btn-danger btn-sm">
              <span v-if="prod.quantity===1">Retirer</span>
              <span v-else>Diminuer</span>
            </button>
          </td>
        </tr>
        <tr >
          <td class=""></td>
          <td class=""></td>
          <td class=""></td>
          <td class=""></td>
          <td  colspan="4">
           <h5 class="text-white"> {{totalPrix}}</h5>
          </td>
        </tr>
        </tbody>
      </table>






  </div>

  <pre>


  </pre>
</template>

<script setup>

import {ref} from "@vue/reactivity";
import {axiosService} from "../../../services/axios.service";
import {computed, onMounted, watch} from "vue";
import { useStore } from "vuex"
const store = useStore()

const loading = ref(false)
const curreentPage = ref(1)
const parpage = ref(5)

watch(curreentPage , (value)=>{
  store.commit('product/SET_CURRENTEPAGE',value)
})

// const products = computed(()=>{
//   return store.getters["product/produitsTraite"]
// })

const lines = computed(()=>{
  return store.getters["cart/products"]
})

const totalPrix = computed(()=>{
  return store.getters["cart/totalPrix"]
})
const loadProducts =async ()=>{
  loading.value = true
  try {
    const rep = await axiosService.get('/admin/products')
    await  store.dispatch('product/GET_PRODUCTS', rep.data.data)
    loading.value = false
  }catch (e) {
    loading.value = false
  }
}

const Diminuer = (prod)=>{
  prod.quantity--
  store.commit('cart/SET_CHANGE_QUANTITY',prod)
}

const pageNombers = ()=>{
  console.log(store.getters["product/pageCount"])
  return store.getters["product/pageCount"]
}

onMounted(()=>{
  loadProducts()
  pageNombers()
  store.commit('product/SET_PAGESIZE',parpage)
})
</script>

<style scoped>

</style>
