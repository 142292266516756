const state = {
    tiket: null,
    products:[]
};


const getters = {
    currentuser : (state)=> state.currentuser
}


const actions= {
    GET_USER( { commit },user ){
        commit('SET_USER',user)
    }
};
const mutations = {
    SET_USER (state,payload){
        state.tiket = payload
    }
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}