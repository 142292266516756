import store from './../store/index'
// const API_URL = 'http://127.0.0.1:8000/api/v1';
const API_URL = 'https://apinovissi.appsridge.com/api/v1';
// const API_URL = 'http://damssan.com:8000/api/v1';
// const API_URL = process.env.VUE_APP_URL

import axios from 'axios';
import {ref} from "@vue/reactivity";


const user = ref( store.state.auth.user || {})

const axiosService = axios.create({
    baseURL: API_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        // 'X-Requested-With': 'XMLHttpRequest',
        // 'Access-Control-Allow-Origin': '*'
    }
})
if (user && user.value.token) {
    axiosService.defaults.headers.common["Authorization"] = 'Bearer ' +user.value.token;
} else {
    delete axiosService.defaults.headers.common["Authorization"];
}
export { axiosService }
