const state = {

    products:[],
    currentPage:1,
    pageSize:5
};


const getters = {
    products : state=> state.products,
    productsTotal : state=> state.products.length,
    currentPage : state=> state.currentPage,
    pageSize : state=> state.pageSize,
    produitsTraite: state=>{
        let index = (state.currentPage - 1 ) * state.pageSize
        return state.products.slice(index,index+ state.pageSize)
    },
    pageCount:state=>Math.ceil(state.products / state.pageSize)
}


const actions= {
    GET_PRODUCTS( { commit },product ){
        commit('SET_PRODUCTS',product)
    }
};
const mutations = {
    SET_PRODUCTS (state,product){
        state.products = product
    },

    SET_CURRENTEPAGE (state,page){
        state.currentPage = page
    },
    SET_PAGESIZE(state,size){
            state.pageSize = size
            state.currentPage = 1
    }
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}