<template>
  <Breadcrumb :menu="bread"/>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8">

              <form-boutique @addBoutique="addProduct"  :updateItem="updateItem"/>

            </div>
          </div>


        </div>


      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <b-spinner variant="primary"  v-if="loading" />
              <list-boutique v-else @updateBoutique="updateBoutique" @deleteProduct="deleteProduct" :datas="datas" baseUrl="/admin/boutiques"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import Breadcrumb from "../../components/elements/breadcrumb";
import { onMounted} from "vue";
import useGetdata from "../../composable/useGetdata"
import {ref} from "@vue/reactivity";
import FormBoutique from "../../components/elements/boutiques/formBoutique";
import ListBoutique from "../../components/elements/boutiques/listBoutique";

const baseUrl =  ('/admin/boutiques')

const updateItem = ref({})

const bread = {
  title:null,
  link:'/admin/boutiques/store',
  links:[
    { title:'Boutique',link:'/admin/boutiques/index'},
    { title:'Liste des Boutiques',link:'/admin/boutiques/index'}
  ]
}




const { loading ,datas , fetchData} =  useGetdata(baseUrl)

const addProduct = (data)=>{
  if (!data.data.id)
    datas.value.data.unshift(data.data)
  fetchData()
}
const deleteProduct = ()=>{
  fetchData()
  // alert('fdfff')
}
const updateBoutique = (prod)=>{
  updateItem.value = prod
}

onMounted(  ()=>{
  fetchData()
})

</script>

<style scoped>

</style>
