import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'

import Login from '@/pages/auth/login'
import Register from '@/pages/auth/register'
import Dashboard from '@/pages/admin/dashboard'

import IndexEditionnovissis from '@/pages/editionnovissi/index-editionnovissi'
import StoreEditionnovissis from '@/pages/editionnovissi/store-editionnovissi'
import EditionEtionnovissis from '@/pages/editionnovissi/store-editionnovissi'
import ImporEtionnovissis from '@/pages/editionnovissi/import-editionnovissi'


import IndexUsers from '@/pages/users/index-user'
import StoreUsers from '@/pages/users/store-user'


 import IndexBeneficiaires from '../pages/beneficiaires/index-beneficiaires'
 import ShownovissisBeneficiaires from '../pages/beneficiaires/show-beneficiaires'
 import OperationEditions from '../pages/novissi/OperationEditions'


 import IndexProduits from '../pages/produits/index-produits'

 import IndexBoutique from '../pages/boutiques/index-boutiques'

 import IndexEtats from '../pages/etats/index-etats'
 import ProfileAction from './../pages/novissi/profils/ProfileAction'


 import IndexNovissi from '../pages/novissi/index-novissi'
 import DashbordNovissi from '../pages/novissi/DashbordNovissi'

 import Notificationssms from    './../pages/novissi/notifications-sms'
 import Beneficiaires from    './../pages/novissi/beneficiaires'

import IndexTickets from '@/pages/tickets/index-tickets'
import StoreTickets from '@/pages/tickets/store-tickets'
import EditTickets from '@/pages/tickets/store-tickets'

import AuthLayout from '@/layouts/auth'
import AdminLayout from '@/layouts/admin'
import NotFound from "../pages/NotFound";


const routes = [
  {
    path: '/',
    name: 'AuthLayout',
    component: AuthLayout,
    children: [
      {
        path: '/',
        name: 'Login',
        title: 'Connexion',
        component: Login,
        meta: {
          title: 'Connexion',
        }
      },{
        path: '/login',
        name: 'Login',
        title: 'Connexion',
        component: Login,
        meta: {
          title: 'Connexion',
        }
      },
      {
        path: '/register',
        name: 'Register',
        title: 'Inscription',
        component: Register
      },
    ]
  },



  {
    path: '/admin',
    name: 'AdminLayout',
    component: AdminLayout,
    children: [
      {
        path: ':dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard ',
        }
      },
      {
        path: 'users/index',
        name: 'IndexUsers',
        component: IndexUsers,
      },
      {
        path: 'users/store',
        name: 'StoreUsers',
        component: StoreUsers,
      },
      {
        path: 'users/:id/edit',
        name: 'EditUsers',
        component: StoreUsers,
      },
      {
        path: 'users/:id/beneficiaires',
        name: 'ShowUsers',
        component: ShownovissisBeneficiaires,
      },


      {
        path: 'editionnovissi/index',
        name: 'IndexEditionnovissis',
        component: IndexEditionnovissis,
        meta: {
          title: 'Liste des éditions de novissi ',
        }
      },
      {
        path: 'editionnovissi/store',
        name: 'StoreEditionnovissis',
        component: StoreEditionnovissis,
      },
      {
        path: 'editionnovissi/:id/edit',
        name: 'EditionEtionnovissis',
        component: EditionEtionnovissis,
      },
      {
        path: 'editionnovissi/:id/import',
        name: 'ImporEtionnovissis',
        component: ImporEtionnovissis,
      },
      {
        path: 'editionnovissi/:id/beneficiaires',
        name: 'IndexEditionnovissisBeneficiaires',
        component: IndexBeneficiaires,

      },

      {
        path: 'editionnovissi/:id/beneficiaires/:profilid/show',
        name: 'ShownovissisBeneficiaires',
        component: ShownovissisBeneficiaires,

      },


      {
        path: 'tickets/index',
        name: 'IndexTickets',
        component: IndexTickets,
        meta: {title: 'Liste des tickets' }
      }, {
        path: 'tickets/store',
        name: 'StoreTickets',
        component: StoreTickets,
      },
      {
        path: 'tickets/:id/edit',
        name: 'EditTickets',
        component: EditTickets,
      },

      {
        path: 'tickets/:id/show',
        name: 'ShowTickets',
        component: EditTickets,
      },
      {
        path: 'beneficiaires/index',
        name: 'IndexBeneficiaires',
        component: IndexBeneficiaires,
      },



      {
        path: 'produits/index',
        name: 'IndexProduits',
        component: IndexProduits,
      },


      {
        path: 'novissi/index',
        name: 'IndexNovissi',
        component: IndexNovissi,
      },
      {
        path: 'novissi/:id/dashbordnovissi',
        name: 'DashbordNovissi',
        component: DashbordNovissi,
        children:[
          {
            path:'',
            name:'Notifications-sms',
            default:  Notificationssms,
            component:   Notificationssms
          },
          {
            path:'beneficiaires',
            name:'Beneficiaires',
            component: Beneficiaires
          },
          {
            path:'operationeditions',
            name:'OperationEditions',
            component: OperationEditions
          }
        ]

      },

      {
        path:'novissi/:id/dashbordnovissi/:userid/index',
        name: 'ProfileAction',
        component:ProfileAction,
      },

      {
        path: 'boutiques/index',
        name: 'IndexBoutique',
        component: IndexBoutique,
      },
      {
        path: 'etats/index',
        name: 'IndexEtats',
        component: IndexEtats,
      },





    ]
  },


  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  saveScrollPosition: true
})

const DEFAULT_TITLE = 'BIENVENUE SUR NOVISSI';

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/', '/login', '/register',];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem('vuex'))?.auth.status.loggedIn;

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }

  await nextTick()
  document.title = to.meta.title || DEFAULT_TITLE;



})
export default router
