<template>
  <Breadcrumb :menu="bread"/>


  <div class="row" v-if="user">
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column align-items-center text-center">
            <img :src="user.img" alt="Admin" class="rounded-circle p-1 bg-primary" width="110">
            <div class="mt-3">
              <h4>{{user.profil.fullname}}</h4>
              <p class="text-secondary mb-1">{{user.profil.profession}}</p>
              <p class="text-muted font-size-sm mx-2">{{user.profil.telephone}}</p>
              <button class="btn btn-primary  mx-2">Follow</button>
              <button class="btn btn-outline-primary">Message</button>
            </div>
          </div>
          <hr class="my-4">
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Nom prénom</h6>
              <span class="text-secondary">{{user.profil.fullname}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Téléphone</h6>
              <span class="text-secondary">{{user.profil.telephone}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Sexe</h6>
              <span class="text-secondary">{{user.profil.sexe}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Date de naissance</h6>
              <span class="text-secondary">{{user.profil.dateness}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                Type de piece</h6>
              <span class="text-secondary">{{user.profil.typepiece}} | {{user.profil.numpiece}} </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Nom</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <input type="text" class="form-control" value="John Doe">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Email</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <input type="text" class="form-control" value="john@example.com">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Phone</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <input type="text" class="form-control" value="(239) 816-9029">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Mobile</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <input type="text" class="form-control" value="(320) 380-4539">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-3">
              <h6 class="mb-0">Address</h6>
            </div>
            <div class="col-sm-9 text-secondary">
              <input type="text" class="form-control" value="Bay Area, San Francisco, CA">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-9 text-secondary">
              <input type="button" class="btn btn-primary px-4" value="Save Changes">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">

            <template v-for="edition in user.profil.editionnovissis" :key="edition.id">
             <div class="d-flex justify-content-between">
              <div class="">
                <h5 class="d-flex align-items-center mb-3 text-primary align-items-center">{{edition.name}}  </h5>
                <p>
                  Date : {{ convertTodat(edition.datedeb) }} a  {{ convertTodat(edition.datefin) }}
                </p>
               <div class="">
                 <div> Montant : <strong class="text-danger"> {{edition.montant}}</strong>    <span class="mr-3"> Etat : <b-badge variant="success"> Livré </b-badge></span> </div>

               </div>
              </div>
               <div class="">
                  <router-link :to="{name:'EditEditionnovissis',params:{id:edition.id}}" class="btn btn-primary">
                    consulter
                  </router-link>
               </div>
             </div>
              <hr>
            </template>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <pre>
    {{user}}
  </pre>

</template>

<script setup>



import Breadcrumb from "../../components/elements/breadcrumb";
import {onMounted, ref} from "vue";
import {useRoute} from 'vue-router'
import {axiosService} from "../../services/axios.service";
import convertTodat from "../../_helpers/dateHerper";
// import { DateTime} from 'luxon'
const basUrl = ref('/admin/beneficiaires');
const user = ref(null);

const router = useRoute()
const bread = {
  title:'Liste des bénéficiaires',
  link:'/admin/beneficiaires/index',
  links:[
    { title:'bénéficiaires',link:'/admin/beneficiaires/index'},
    { title:'Liste des bénéficiaires',link:'/admin/beneficiaires/index'}
  ]
}

const loadUser = async ()=>{
    const id = await router.params?.id
    try {
      const rep =await axiosService.get(basUrl.value+'/'+id)
      user.value = rep.data.data

    }catch (e) {
      console.log(e)
    }
}

onMounted(()=>{
  document.title =  'Liste des bénéficiaires'

  loadUser()
})

</script>

<style scoped>

</style>
