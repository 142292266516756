<template>
  <span v-if="online===1" class="badge bg-success">En ligne </span>
  <span v-else class="badge bg-danger">Hors ligne</span>
</template>

<script>
export default {
  name: "isonline",
  props:{
    online:{
      type:Number,
      default:0,
      require:true
    }
  },
  setup(){

  }
}
</script>

<style scoped>

</style>