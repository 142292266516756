<template>
  <h4>Envoyer les notifications aux bénéficiaires</h4>

  <hr>
  <div class="d-flex justify-content-between" v-if="!sendFinish">
    <div class="">
      <button class="btn btn-primary btn-lg" @click="callGeneRate" v-if="!canGenerate">
        <span> Générer </span>
        <b-spinner v-if="loading"></b-spinner>
      </button>
      <button class="btn  btn-lg" :class="{'btn-danger':!loading,'btn-success':loading}" v-else @click="geneRate">
        <span v-if="!loading"> Envoyé les messages</span>
        <span v-else> SMS en cours d'envoi </span>
        <b-spinner v-if="loading"></b-spinner>
      </button>
    </div>
    <div class="">
      <h4> Totals {{ total }}</h4>
    </div>
  </div>


</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useRoute} from "vue-router";
import useSave from "../../composable/useSave";
import {createToast} from "mosha-vue-toastify";

const url = '/admin/generatesms'
const route = useRoute()
// const {save, loading, formerrors: errofs, etat, getdata} = useSave()
const {save, loading,  etat, getdata} = useSave()
const canGenerate = ref(false)
const total = ref(0)
const sendFinish = ref(false)
onMounted(() => {

})
const geneRate = async () => {
  if (confirm('Générer les SMS')) {
    await save(url, {editionid: route.params.id, generate: 1})
    if (etat.value) {
      canGenerate.value = true
      createToast('Les SMS ont été envoyé avec succès !',{type:"success"})
      sendFinish.value = true
    }
  }

}

const callGeneRate = async () => {
  await save(url, {editionid: route.params.id})

  if (etat.value) {
    canGenerate.value = true
    total.value = getdata.value.total


  }

}
</script>

<style scoped>

</style>
