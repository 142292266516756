const state = {
    citoyens: null,
    filecsv: {},
    isFileLoading:false
};
const getters = {
    citoyens : (state)=> state.citoyens,
    filecsv : (state)=> state.filecsv,
    isFileLoading : (state)=> state.isFileLoading,
}


const actions= {
    GET_CITOYENS( { commit },citoyens ){
        commit('SET_CITOYENS',citoyens)
    },
    GET_FILECSV( { commit },filecsv ){
        commit('SET_FILECSV',filecsv)
    },
    GET_ISFILELOADING( { commit },loading ){
        commit('SET_ISFILELOADING',loading)
    }
};
const mutations = {
    SET_CITOYENS (state,payload){
        state.citoyens = payload
    },
    SET_FILECSV (state,payload){
        state.filecsv = payload
    },
    SET_ISFILELOADING (state,payload){
        state.isFileLoading = payload
    },
};


export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}