<template>
  <Breadcrumb :menu="bread"/>

  <div class="card">
    <div class="card-body">

      <div class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;"  v-if="isLoading"></b-spinner>
      </div>

      <div class="table-responsive" >
        <h2>{{pageTitle}}</h2>

        <input type="text" name="" class="form-control" id="" v-model="q" >
        <hr>
        <h3>
          {{q }}
        </h3>
        <b-overlay :show="isLoading" rounded="sm">
          <table id="example2" class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Id</th>
              <th>Nom</th>
              <th>Date début | Date fin</th>
              <th>Package</th>
              <th>Profils</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>


            <tr  v-for="edition in lists.data" :key="edition.id">
              <td>{{edition.id}}</td>
              <td>{{edition.name}}</td>
              <td>{{edition.datedeb + ' | '+edition.datefin}}</td>

              <td>
                <span v-html="p.name + ' | '+ p.totalFormat" class="badge bg-dark m-1 text-decoration-none" v-for="p in edition.packages" :key="p.id">

                </span>
              </td>
              <td>{{edition.profils_count}}</td>

              <td>
                <div class="d-flex order-actions">
                  <a
                      title="supprimer"
                      href="#"
                      @click.prevent="deleteUser(edition.id)"
                      class="text-info bg-light-info border-0"
                  ><i class="bx bxs-trash"></i
                  ></a>
                  <router-link  title="Modifier" :to="{name:'EditionEtionnovissis',params:{id:edition.id}}"
                               class="ms-4 text-success bg-light-success border-0"
                  ><i class="bx bxs-edit"></i
                  ></router-link>

                    <router-link   title="consulter" :to="{name:'DashbordNovissi',params:{ id:edition.id}}"   class="ms-4 text-primary bg-light-primary border-0"
                    ><i class="bx bxs-user"></i
                    ></router-link>





                </div>

<!--                <router-link :to="{name:'EditEditionnovissis',params:{id:edition.id}}"  class="ms-4 btn btn-dark"-->
<!--                > <i class="bx bxs-edit"> Bénéficiaires</i-->
<!--                ></router-link>-->
              </td>
            </tr>

            </tbody>
            <tfoot>
            <tr>
              <th>Id</th>
              <th>Nom</th>
              <th>Date début | Date fin</th>
              <th>Package</th>
              <th>Profils</th>
              <th>Actions</th>
            </tr>
            </tfoot>
          </table>
        </b-overlay>
      </div>


      <nav aria-label="Page navigation example">
        <ul class="pagination">

          <li class="page-item" :class="{'active':p.active}" v-for="(p  , index) in lists.meta?.links" :key="index" @click.prevent="goTo(p.url)">
            <button class="page-link"  v-html="p.label" ></button>
          </li>

        </ul>
      </nav>


    </div>
  </div>



<!--  <pre>-->
<!--    ici-->
<!--    {{lists}}-->
<!--  </pre>-->



</template>

<script setup>
import Breadcrumb from "../../components/elements/breadcrumb";

import useFetch from "../../composable/useFetch";
import {onMounted} from "vue";
import {ref} from "@vue/reactivity";





const bread = {
  title:'Ajouter une éditions de novissi ',
  link:'/admin/editionnovissi/store',
  links:[
    { title:'Edition de novissi',link:'/admin/editionnovissi/index'},
    { title:'Liste des éditions novissi',link:'/admin/editionnovissi/index'},
    { title:'Ajouter une éditions de novissi ',link:'/admin/editionnovissi/store'}
  ]
}


const pageTitle = "Liste des éditions de novissi"
const basUrl = ref('/admin/editionnovissi');
const {lists , getResultsLink,isLoading , q,deleteModel}  = useFetch(basUrl.value)


const goTo = (url) => {
  basUrl.basUrl = url
  getResultsLink()
}
onMounted(() => {
  getResultsLink()
})

const deleteUser = (id)=>{
  const rep = confirm("Supprimer l'enregistrement ?")
  rep? deleteModel(id) : null

  return   getResultsLink()


}

</script>

<style scoped>

</style>
