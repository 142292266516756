<template>
  <Breadcrumb :menu="bread"/>
  <div class="row">
    <div class="col">
      <div class="card radius-10 mb-0">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="mb-1">Liste des Editions</h5>
            </div>
            <div class="ms-auto">
              <router-link  to="/admin/editionnovissi/store" class="btn btn-primary btn-sm radius-30"
              >Ajouter une Edition</router-link
              >
            </div>
          </div>

          <div class="table-responsive mt-3">
           <div class="col-lg d-flex justify-content-between "  v-if="loading">
             <b-spinner >......Chargement</b-spinner>
           </div>

            <table class="table table-striped mb-0" v-else>
              <thead class="table-light">
              <tr>
                <th>#</th>
                <th>Nom</th>
                <th>Date</th>
                <th>Profils</th>
                <th>Package</th>
                <th>Montant</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>

              <tr v-for="novissi in datas.data" :key="novissi.id">
                <td>{{novissi.id}}</td>
                <td>
                  <strong>{{novissi.name}}</strong>
                </td>
                <td>
                  {{convertTodat(novissi.datedeb)}} |
                  {{convertTodat(novissi.datefin)}} |
                </td>
                <td>{{novissi.profils_count}}</td>
                <td class="">
<!--                   <span v-html="novissi.name + ' | '+ novissi.totalFormat" class="badge bg-dark m-1 text-decoration-none" v-for="novissi in novissi.packages" :key="novissi.id"> </span>-->
                {{novissi.packages.length}}


                </td>

                <td> </td>
                <td>
                  <div class="d-flex order-actions">
<!--                    <a-->
<!--                        href="javascript:;"-->
<!--                        class="text-danger bg-light-danger border-0"-->
<!--                    ><i class="bx bxs-trash"></i-->
<!--                    ></a>-->
                    <router-link :to="{name:'DashbordNovissi',params:{id:novissi.id}}"  class="ms-4 text-primary bg-light-primary border-0" ><i class="bx bxs-edit"></i></router-link>
                  </div>
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  <pre>-->
<!--{{datas}}-->
<!--</pre>-->
</template>



<script setup>

import Breadcrumb from "../../components/elements/breadcrumb";
import useGetdata from "../../composable/useGetdata";
import {onMounted} from "vue";
import convertTodat from "../../_helpers/dateHerper";

const bread = {
  title:null,
  link:'/admin/novissi/store',
  links:[
    { title:'Editions',link:'/admin/novissi/index'},
    { title:'Liste des éditions',link:'/admin/novissi/index'}
  ]
}

const {datas,loading,fetchData } = useGetdata('/admin/novissis')

onMounted(  ()=>{
   fetchData()

})
</script>

<style scoped>

</style>
