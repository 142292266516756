<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <!-- <img
          src="/assets/images/logo-icon.png"
          class="logo-icon"
          alt="logo icon"
        /> -->
      </div>
      <div>
       <router-link to="/admin/dashboard">
         <h4 class="logo-text">NOVISSI</h4>
       </router-link>
      </div>
      <div class="toggle-icon ms-auto">
        <i class="bx bx-arrow-to-left"></i>
      </div>
    </div>
    <!--navigation-->
    <ul class="metismenu" id="menu">


      <li>
        <a href="javascript:;" class="has-arrow">
          <div class="parent-icon"><i class="bx bx-home-circle"></i></div>
          <div class="menu-title">Tableau de bord</div>
        </a>
        <ul>
          <li>
            <router-link to="/admin/dashboard">
              <i class="bx bx-right-arrow-alt"></i>Analytics
            </router-link>
          </li>

        </ul>
      </li>


      <li>
        <a class="has-arrow" href="javascript:;">
          <div class="parent-icon"><i class="bx bx-home-circle"></i></div>
          <div class="menu-title">Gestion Novissi</div>
        </a>

        <ul>
          <li>
            <a class="has-arrow" href="javascript:;"><i class="bx bx-right-arrow-alt"></i>Liste des Novissi</a>
            <ul>
              <li>
                <router-link :to="{name:'IndexNovissi'}"><i class="bx bx-right-arrow-alt"></i> Novissi
                </router-link>
              </li>

            </ul>
          </li>

        </ul>
      </li>

<!--      <li>-->
<!--        <a class="has-arrow" href="javascript:;">-->
<!--          <div class="parent-icon"><i class="bx bx-home-circle"></i></div>-->
<!--          <div class="menu-title">Opérations</div>-->
<!--        </a>-->

<!--        <ul>-->
<!--          <li>-->
<!--            <a class="has-arrow" href="javascript:;"><i class="bx bx-right-arrow-alt"></i>Servir un bénéficiaire</a>-->
<!--            <ul>-->
<!--              <li>-->
<!--                <router-link :to="{name:'IndexProduits'}"><i class="bx bx-right-arrow-alt"></i>Gestion des produits-->
<!--                </router-link>-->
<!--              </li>-->

<!--            </ul>-->
<!--          </li>-->


<!--          <li>-->
<!--            <a class="has-arrow" href="javascript:;"><i class="bx bx-right-arrow-alt"></i>Boutiques</a>-->
<!--            <ul>-->
<!--              <li><a href="javascript:;"> <i class="bx bx-right-arrow-alt"></i>List des Boutiques</a></li>-->
<!--              <li><a href="javascript:;"> <i class="bx bx-right-arrow-alt"></i>Ajouter un boutique</a></li>-->
<!--            </ul>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </li>-->



      <li v-if="currentuser.type==='superadmin'">
        <ul>

          <li>
            <router-link :to="{name:'IndexEditionnovissis'}"><i class="bx bx-right-arrow-alt"></i>Edition Novissi
            </router-link>
          </li>

          <li>
            <router-link :to="{name:'IndexTickets'}"><i class="bx bx-right-arrow-alt"></i>Packages</router-link>
          </li>

          <li>
            <router-link to="/admin/users/index">
              <i class="bx bx-right-arrow-alt"></i>Utilisateurs
            </router-link>
          </li>
<!--          <li>-->
<!--            <router-link to="/admin/beneficiaires/index" exact>-->
<!--              <i class="bx bx-right-arrow-alt"></i>Bénéficiaires-->
<!--            </router-link>-->
<!--          </li>-->
          <li>
            <router-link :to="{name:'IndexProduits'}" exact>
              <i class="bx bx-right-arrow-alt"></i>Produits
            </router-link>

          </li>
          <li>
            <router-link  :to="{name:'IndexBoutique'}" exact>
              <i class="bx bx-right-arrow-alt"></i>Boutique
            </router-link>
          </li>
<!--          <li>-->
<!--            <router-link :to="{name:'IndexBoutique'}"><i class="bx bx-right-arrow-alt"></i>Gestion   boutiques-->
<!--            </router-link>-->
<!--          </li>-->

          <li>
            <router-link :to="{name:'IndexEtats'}"><i class="bx bx-right-arrow-alt"></i>Etats opération
            </router-link>
          </li>



          <!--          <li>-->
          <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Contatcs</a>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Todo List</a>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Invoice</a>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Calendar</a>-->
          <!--          </li>-->
        </ul>
        <a href="javascript:;" class="has-arrow">
          <div class="parent-icon"><i class="bx bx-category"></i></div>
          <div class="menu-title">Application</div>
        </a>
      </li>

<!--      <div>-->
<!--        {{currentuser.type}}-->
<!--        {{currentuser.type=='user'}}-->
<!--      </div>-->


<!--      <li>-->
<!--        <a class="has-arrow" href="javascript:;">-->
<!--          <div class="parent-icon"><i class="bx bx-home-circle"></i></div>-->
<!--          <div class="menu-title">Configuration</div>-->
<!--        </a>-->

<!--        <ul>-->

<!--          <li>-->
<!--            <a class="has-arrow" href="javascript:;"><i class="bx bx-right-arrow-alt"></i>Produits</a>-->
<!--            <ul>-->
<!--              <li>-->
<!--                <router-link :to="{name:'IndexProduits'}"><i class="bx bx-right-arrow-alt"></i>Gestion   produits-->
<!--                </router-link>-->
<!--              </li>-->

<!--            </ul>-->
<!--          </li>-->

<!--          <li>-->
<!--            <a class="has-arrow" href="javascript:;"><i class="bx bx-right-arrow-alt"></i>Boutiques</a>-->
<!--            <ul>-->
<!--              <li>-->
<!--                <router-link :to="{name:'IndexBoutique'}"><i class="bx bx-right-arrow-alt"></i>Gestion   boutiques-->
<!--                </router-link>-->
<!--              </li>-->

<!--            </ul>-->
<!--          </li>-->


<!--          <li>-->
<!--            <a class="has-arrow" href="javascript:;"><i class="bx bx-right-arrow-alt"></i>Etats opération</a>-->
<!--            <ul>-->
<!--              <li>-->
<!--                <router-link :to="{name:'IndexEtats'}"><i class="bx bx-right-arrow-alt"></i>Etats opération-->
<!--                </router-link>-->
<!--              </li>-->

<!--            </ul>-->
<!--          </li>-->


<!--          <li>-->
<!--            <a class="has-arrow" href="javascript:;"><i class="bx bx-right-arrow-alt"></i>Boutiques</a>-->
<!--            <ul>-->
<!--              <li><a href="javascript:;"> <i class="bx bx-right-arrow-alt"></i>List des Boutiques</a></li>-->
<!--              <li><a href="javascript:;"> <i class="bx bx-right-arrow-alt"></i>Ajouter un boutique</a></li>-->
<!--            </ul>-->
<!--          </li>-->



<!--        </ul>-->
<!--      </li>-->


      <!--      <li class="menu-label">UI Elements</li>-->
      <!--      <li>-->
      <!--        <a href="#">-->
      <!--          <div class="parent-icon"><i class="bx bx-cookie"></i></div>-->
      <!--          <div class="menu-title">Widgets</div>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a href="javascript:;" class="has-arrow">-->
      <!--          <div class="parent-icon"><i class="bx bx-cart"></i></div>-->
      <!--          <div class="menu-title">eCommerce</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Products</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Product Details</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Add New Products</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Orders</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-bookmark-heart"></i></div>-->
      <!--          <div class="menu-title">Components</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Alerts</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Accordions</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Badges</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Buttons</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Cards</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Carousels</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>List Groups</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Media Objects</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Modals</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Navs & Tabs</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Navbar</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Pagination</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Popovers & Tooltips</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Progress</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Spinners</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Notifications</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Avatrs & Chips</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-repeat"></i></div>-->
      <!--          <div class="menu-title">Content</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Grid System</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Typography</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Text Utilities</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-donate-blood"></i></div>-->
      <!--          <div class="menu-title">Icons</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Line Icons</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Boxicons</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Feather Icons</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li class="menu-label">Forms & Tables</li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon">-->
      <!--            <i class="bx bx-message-square-edit"></i>-->
      <!--          </div>-->
      <!--          <div class="menu-title">Forms</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Form Elements</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Input Groups</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Forms Layouts</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Form Validation</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Form Wizard</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Text Editor</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>File Upload</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Date Pickers</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Select2</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-grid-alt"></i></div>-->
      <!--          <div class="menu-title">Tables</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Basic Table</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Data Table</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li class="menu-label">Pages</li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-lock"></i></div>-->
      <!--          <div class="menu-title">Authentication</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Sign In</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Sign Up</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Sign In with Header &-->
      <!--              Footer</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Sign Up with Header &-->
      <!--              Footer</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Forgot Password</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Reset Password</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Lock Screen</a-->
      <!--            >-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a href="#">-->
      <!--          <div class="parent-icon"><i class="bx bx-user-circle"></i></div>-->
      <!--          <div class="menu-title">User Profile</div>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a href="#">-->
      <!--          <div class="parent-icon"><i class="bx bx-video-recording"></i></div>-->
      <!--          <div class="menu-title">Timeline</div>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-error"></i></div>-->
      <!--          <div class="menu-title">Errors</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>404 Error</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>500 Error</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Coming Soon</a-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" target="_blank"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Blank Page</a-->
      <!--            >-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a href="#">-->
      <!--          <div class="parent-icon"><i class="bx bx-help-circle"></i></div>-->
      <!--          <div class="menu-title">FAQ</div>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a href="#">-->
      <!--          <div class="parent-icon"><i class="bx bx-diamond"></i></div>-->
      <!--          <div class="menu-title">Pricing</div>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="menu-label">Charts & Maps</li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-line-chart"></i></div>-->
      <!--          <div class="menu-title">Charts</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Apex</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Chartjs</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Highcharts</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-map-alt"></i></div>-->
      <!--          <div class="menu-title">Maps</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Google Maps</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#"><i class="bx bx-right-arrow-alt"></i>Vector Maps</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li class="menu-label">Others</li>-->
      <!--      <li>-->
      <!--        <a class="has-arrow" href="javascript:;">-->
      <!--          <div class="parent-icon"><i class="bx bx-menu"></i></div>-->
      <!--          <div class="menu-title">Menu Levels</div>-->
      <!--        </a>-->
      <!--        <ul>-->
      <!--          <li>-->
      <!--            <a class="has-arrow" href="javascript:;"-->
      <!--              ><i class="bx bx-right-arrow-alt"></i>Level One</a-->
      <!--            >-->
      <!--            <ul>-->
      <!--              <li>-->
      <!--                <a class="has-arrow" href="javascript:;"-->
      <!--                  ><i class="bx bx-right-arrow-alt"></i>Level Two</a-->
      <!--                >-->
      <!--                <ul>-->
      <!--                  <li>-->
      <!--                    <a href="javascript:;"-->
      <!--                      ><i class="bx bx-right-arrow-alt"></i>Level Three</a-->
      <!--                    >-->
      <!--                  </li>-->
      <!--                </ul>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a href="#" target="_blank">-->
      <!--          <div class="parent-icon"><i class="bx bx-folder"></i></div>-->
      <!--          <div class="menu-title">Documentation</div>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <a href="https://themeforest.net/user/codervent" target="_blank">-->
      <!--          <div class="parent-icon"><i class="bx bx-support"></i></div>-->
      <!--          <div class="menu-title">Support</div>-->
      <!--        </a>-->
      <!--      </li>-->
    </ul>
    <!--end navigation-->
  </div>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
const store = useStore()

const currentuser = computed(()=> store.state.auth.user?.user || {})
</script>

<style></style>
