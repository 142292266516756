<template>
  <Breadcrumb  :menu="bread"/>

  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;"  v-if="isLoading"></b-spinner>
      </div>
      <b-overlay :show="isLoading" rounded="sm">
      </b-overlay>

      <!-- description="We'll never share your email with anyone else."-->
      <div class="row">
        <div class="col-lg-6">
          <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">

            <b-form-group
                id="input-group-name"
                label="Nom d'utilisateur"
                label-for="name"
            >
              <b-form-input
                  :class="{'is-invalid':httperrors['name']}"
                  id="name"
                  v-model="form.name"
                  type="text"
                  placeholder="Nom d'utilisateur"
                  required
              ></b-form-input>
              <div  class="invalid-feedback">{{httperrors.name?.join(' ')}}</div>
            </b-form-group>

            <b-form-group
                id="input-group-name"
                label="Date début"
                label-for="name"
            >
              <Datepicker

                  v-model="form.datedeb"

              />
              <div  class="invalid-feedback">{{httperrors.date_deb?.join(' ')}}</div>
            </b-form-group>

            <b-form-group
                id="input-group-name"
                label="Date de fin"
                label-for="name"
            >
              <Datepicker
                  v-model="form.datefin"

              />
              <div  class="invalid-feedback">{{httperrors.date_fin?.join(' ')}}</div>
            </b-form-group>

            <b-form-group
                id="input-group-ticket"
                label="Selectionner le Package"
                label-for="ticket"
            >


<!--              <select   v-model="form.ticket_id" class="form-select" id="inputProductType" v-if="tickets">-->
<!--&lt;!&ndash;                <option value="0">Selectionner un ticket </option>&ndash;&gt;-->
<!--                <option :value="ticket.id" v-for="(ticket,index) in tickets" :key="index"> {{ticket.name}} ({{ticket.total}}) ( {{ticket.products.map(ele=>ele.name).join(' | ')}} )    </option>-->

<!--              </select>-->

<!--              <b-form-select   @change="onCangeTicket" required class="p-2 hiden" v-model="form.ticket_id" :options="[{value:null,text:'Selectionner un ticket'},...optionsTiket]"></b-form-select>-->
              <v-select multiple v-model="modelPackage"    :options="optionsPackages" />


              <div  class="invalid-feedback">{{httperrors.ticket?.join(' ')}}</div>
            </b-form-group>

            <br>

            <b-button type="submit" variant="primary">

              <div class="span" v-if="form.id">
                  <span class="">
                     <i  class="fadeIn animated bx bx-pencil"></i> Modifier
                    <b-spinner  v-if="isLoading" small variant="light"></b-spinner>
                  </span>
              </div>

              <div class="span" v-else>
                 <span class="" v-if="isLoading">
                    <b-spinner small variant="light"></b-spinner>
                  </span>
                <span v-else>
                    <i class="fadeIn animated bx bx-plus-circle"></i> Ajouter
                </span>
              </div>
            </b-button>

            <b-button  v-if="!form.id" type="reset" class="mx-3" variant="danger">Reset</b-button>

          </b-form>





        </div>

        <div class="col-lg-6">


          <div class="">
             {{isfileLoading}}
          </div>

        <div class="" v-if="false">

          <h5>Charger le fichier des bénéficiaire</h5>
          <div  v-if="!citoyens">
            <UploadFile/>
          </div>

          <div class="" v-else>
           <div class="d-flex justify-content-between">
             <button @click.prevent="onGenerate" class="btn btn-danger btn-block ">Générer les comptes pour {{form.name}}</button>
             <button @click.prevent="close" class="btn btn-dark   ">Fermer <i class="fas fa-close"></i></button>
           </div>

            <div class="">
              <hr>
              <h4>Total : {{citoyens[0].length}}</h4>
            </div>
            <hr>
            <b-spinner variant="danger" v-if="loadingData" />
          </div>

        </div>

        </div>







        <PreviewListCitoyen v-if="citoyens?.length"  :data="citoyens"/>

      </div>



      <div :class="tikets"></div>

    </div>
  </div>





</template>

<script setup>

import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

const router = useRouter();
const bread = {
  title:'Ajouter une éditions  ',
  link:'/admin/editionnovissi/store',
  links:[
    { title:'Edition ',link:'/admin/editionnovissi/index'},
    { title:'Liste des éditions novissi',link:'/admin/editionnovissi/index'},
    { title:'Ajouter une éditions  ',link:'/admin/editionnovissi/store'}
  ]
}

import Breadcrumb from "../../components/elements/breadcrumb";
import {ref, nextTick, onMounted, computed, watch} from 'vue'
import {useRoute} from "vue-router";
import {createToast} from "mosha-vue-toastify";
import useFetch from "../../composable/useFetch";
import UploadFile from "../../components/elements/UploadFile";
import PreviewListCitoyen from "../../components/elements/previewListCitoyen";
import {axiosService} from "../../services/axios.service";
import tikets from "../../store/tikets";
// import _ from "lodash";
const route = useRoute()
const store = useStore()

const basUrl = ref('/admin/editionnovissi');
const basUrlTickets = ref('/admin/tickets');
const show = ref(true)
const loadingData = ref(false)
const tickets = ref([])
const modelPackage = ref([])





const uploadPercentage = ref(0)

const citoyens= computed(()=>store.getters['citoyens/citoyens'] || null)
const formData = computed(()=>store.getters['citoyens/filecsv'] || null)
const isfileLoading = computed(()=>store.getters['citoyens/isFileLoading'] || null)

// const optionsTiket = computed(()=>{
//   return tickets.value.map(ticket=>{
//     return {
//       value: ticket.id,
//       text: ticket.name + ' - ' + ticket.total + ' - ' +  ticket.products.map(ele=>ele.name).join(' | ')
//     }
//
//   })
// })

const optionsPackages = computed(()=>{
  return tickets.value.map(ticket=>{
    return {
      code: ticket.id,
      label: ticket.name + ' - ' + ticket.total + ' - ' +  ticket.products.map(ele=>ele.name).join(' | ')
    }

  })
})


const form = ref({
  name: '',
  datedeb: new Date() ,
  datefin: new Date(),


  packagesids:[]
})

const getTickets = async ()=>{
  try {
    const res = await axiosService.get(basUrlTickets.value)
    tickets.value = res.data.data
  }catch (e) {
    console.log(e)
  }
}

// const onCangeTicket = function (id){
//   const tiket =    tickets.value.find(ele=>ele.id ===id)
//   form.value.montant =  tiket.total
//
// }

watch(modelPackage,(value)=>{
  let selectValues = JSON.parse(JSON.stringify(value))
  form.value.packagesids  = { ...selectValues.map(tags => tags.code) }
})




const close = ()=>{
    store.dispatch('citoyens/GET_CITOYENS',null)
}
const onGenerate = async ()=>{
  loadingData.value = true
 try {
   formData.value.append('novissi_id', route.params.id);
   const rep = await  axiosService.post('/admin/generate',formData.value,{
     onUploadProgress:function (progressEvent) {
       uploadPercentage.value = parseInt(Math.round((progressEvent.loaded/progressEvent.total) *100 ))
     }.bind(this)
   })
   loadingData.value = false
   console.log(rep.data)
   }catch (e) {
   loadingData.value = false
   console.log(e)
   }
}

// watch(()=> _.cloneDeep(form.value.online)  , (currentValue,oldF) => {
//   console.log(currentValue,oldF)
//   currentValue ?  form.value.online = 1 : form.value.online = 0
// });

const { singleData,getSingle,save ,isLoading,httperrors,message,etat}  = useFetch(basUrl.value)

onMounted(async ()=>{
  const  id = route.params.id
  if (id ){
    await getSingle(id)
    form.value = singleData.value
    form.value.online = singleData.value.online === 1 ? true : false


    modelPackage.value = singleData.value.packages.map(ticket=>{
      return {
        code: ticket.id,
        label: ticket.name + ' - ' + ticket.total + ' - ' +  ticket.products.map(ele=>ele.name).join(' | ')
      }
    })
    // console.log(JSON.parse(JSON.stringify(singleData.value)))
  }

  getTickets()

  // resolv(3,-2,-5)
      // = 3x² - 2x - 5 = 0

})


const onSubmit =async () => {
  form.value.online = form.value.online ? 1 :  0
  form.value.datedeb =convertDateToTime(form.value.datedeb)
  form.value.datefin = convertDateToTime(form.value.datefin)



  await save(form.value)

  if (etat.value){
    createToast(message.value ,{type:"success",timeout:10000})
    router.push('/admin/editionnovissi/index')
  }else {
    createToast(message.value ,{type:"danger"})
  }
}

const convertDateToTime = (date) =>{
  const today = new Date(date);
  return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

}

const onReset = (event) => {
  event.preventDefault()
  // Reset our form values
  form.value = ''

  nextTick(() => {
    show.value = true
  })
}

</script>

<style scoped>

</style>
