import { createStore } from 'vuex'
import user from './user.js'
import  utilisateur from './utilisateur.js'
import  auth from './auth-module.js'
import  citoyens from './citoyen.js'
import  ticket from './tikets.js'
import  product from './product.js'
import  cart from './cart.js'
import createPersistedState from "vuex-persistedstate";



export default createStore({
  state: {

  },

  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    utilisateur,
    auth,
    citoyens,
    ticket,
    product,
    cart,
  },
  plugins: [createPersistedState({
    paths:['auth']
  })],

})
