<template>
  <hr>
  <div class="my-3 ">
    <b-progress :value="uploadPercentage" v-if="isLoading"></b-progress>
    <template v-else>
      <label for="csv">Sélectionnez le fichier csv </label>
      <input  accept=".csv" :class="{'is-invalid':hasError}" type="file" name="file" id="csv" class="form-control"  @change="handleFileUploads( $event )">
    </template>
  </div>
  <div class="" v-if="users.length" >
    <h6 class="text-danger">  {{users.length}} Utilisateurs</h6>
    <hr>
    <button v-if="!loadingData" @click="onGenerate"  class="btn btn-dark btn-block btn-sm">Uploader le fichier   <i class="bx bx-file"></i></button>
    <b-spinner v-else></b-spinner>
  </div>

  <div :class="citoyens"></div>
</template>

<script setup>
import {computed, defineProps, ref} from 'vue'
import {createToast} from "mosha-vue-toastify";
import {axiosService} from "../../services/axios.service";
import {useStore} from "vuex";
const props = defineProps(['package_id','editionnovissi_id'])
const store = useStore()
const uploadUrl  =  ref(`/admin/generate?package_id=${props.package_id}&editionnovissi_id=${props.package_id}`)
const users = ref([])



const file = ref('')
const hasError = ref(false)
const isLoading = ref(false)
const loadingData = ref(false)
const uploadPercentage = ref(0)

const globalformData = ref(new  FormData())



const citoyens= computed(()=>store.getters['citoyens/citoyens'] || null)
// const formData = computed(()=>store.getters['citoyens/filecsv'] || null)
// const isfileLoading = computed(()=>store.getters['citoyens/isFileLoading'] || null)


const handleFileUploads =async ($event) =>{
  const uploadFile = $event.target.files[0];

  if (uploadFile.type !== 'text/csv'){
    hasError.value = true
    return  createToast("Le fichier n'est pas valide",{type:"danger",'position':'bottom-right'})
  }

  uploadPercentage.value = 0
  file.value =uploadFile
  let formData = new FormData();
  formData.append('file', file.value);
  globalformData.value = formData
  store.commit('citoyens/SET_FILECSV',formData)
  store.commit('citoyens/SET_ISFILELOADING',true)
  try {
    isLoading.value = true

    const res = await axiosService.post('/admin/uploadcsv',formData,{
      onUploadProgress:function (progressEvent) {
        uploadPercentage.value = parseInt(Math.round((progressEvent.loaded/progressEvent.total) *100 ))
      }.bind(this)
    })


    users.value = res.data[0]
    await  store.dispatch('citoyens/GET_CITOYENS',res.data)
    store.commit('citoyens/SET_ISFILELOADING',false)
    createToast('Le fichier a bien été uploader avec succès ',{type:"success",'position':'bottom-right'})
  }catch (e) {
    console.log(e.response.data)
    console.log('FAILURE!!');
    hasError.value = true
    store.commit('citoyens/SET_ISFILELOADING',false)
  }finally {
    isLoading.value = false
  }
}



const onGenerate = async ()=>{
  loadingData.value = true
  try {

    globalformData.value.append('package_id', props.package_id);
    globalformData.value.append('editionnovissi_id', props.editionnovissi_id);
    const rep = await  axiosService.post(uploadUrl.value,globalformData.value,{
      onUploadProgress:function (progressEvent) {
        uploadPercentage.value = parseInt(Math.round((progressEvent.loaded/progressEvent.total) *100 ))
      }.bind(this)
    })
    loadingData.value = false
    console.log(rep.data)
  }catch (e) {
    loadingData.value = false
    console.log(e)
  }
}


</script>

<style scoped>

</style>
