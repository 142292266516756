<template>

  <form  @submit.prevent="onSubmit">

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Nom du produit</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input :class="{'is-invalid':formerrors.name}" v-model="form.name" placeholder="Nom du produit" type="text" class="form-control"  >
        <div class="invalid-feedback" v-if="formerrors.name">{{formerrors.name.join(' ')}}</div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Prix du produit</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input :class="{'is-invalid':formerrors.prix}" v-model="form.prix" placeholder="Nom du prix" type="number" class="form-control"  >
        <div class="invalid-feedback" v-if="formerrors.prix">{{formerrors.prix.join(' ')}}</div>
      </div>
    </div>



    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-9 text-secondary">

        <button type="submit"  class="btn btn-primary px-4" >
          <b-spinner small v-if="loading" />
          <span v-if="form.if">Modifier le produit</span>
          <span v-else>Ajouter le produit </span>
        </button>
      </div>
    </div>


  </form>

</template>

<script setup>

import {ref} from "@vue/reactivity";
import useSave from "../../../composable/useSave";
import {createToast} from "mosha-vue-toastify";
import {computed, defineEmits, defineProps, watch} from "vue";

const props = defineProps({
  updateItem:Object
})

const itemToUpdate = computed(()=>props.updateItem)
const emits = defineEmits()
const baseUrl = '/admin/products'
const form = ref({
  name:'',
  prix:'',
})

watch(itemToUpdate,(value)=>{
  form.value = value
})

const  {loading,etat,message,save,formerrors,getdata }   =  useSave()

const onSubmit =  async ()=>{
  await save(baseUrl,form.value)
  if (etat.value){
    form.value = {}
    createToast(message.value ,{type:"success",timeout:10000})
    emits('add-product',getdata.value)
  }else {
    createToast(message.value ,{type:"danger"})
  }
}
</script>

<style scoped>

</style>