export function  toMutipartedForm (form,mode) {


    if(mode === 'edit' && typeof form.image ==='string'){
        // remove reactive
        const tamp = JSON.parse(JSON.stringify(form))
        delete tamp.image

        return  tamp
    }else {
        // return form
        return objectToFormData(form)
    }

}

export function objectToFormData(obj,form,namespace){
    console.log(obj,form,namespace)
    let fd = form || new FormData()
    let formKey

    for (const property in obj) {

        // eslint-disable-next-line no-prototype-builtins
        if(obj.hasOwnProperty(property)){

            if(namespace){
                formKey = namespace + '['+property+']'
            }else {
                formKey = property
            }

            if(obj[property] instanceof Array){
                for (let i = 0; i < obj[property].length; i++) {
                    objectToFormData(obj[property[i]],fd,`property[${i}]`)
                }
            }else if( typeof obj[property] === 'object' && !(obj[property] instanceof   File)){
                objectToFormData(obj[property],fd,property)
            }else {
                fd.append(formKey,obj[property])
            }

        }
    }
    return fd
}