

  const  state = {
        currentuser:{}
    }

  const getters = {
        currentuser : (state)=> state.currentuser
    }

  const actions = {
        GET_USER( { commit },user ){
            commit('SET_USER',user)
        },
    }
  const  mutations = {
        SET_USER (state,payload){
            state.currentuser = payload
        }
    }



export default {
    strict: true,
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}