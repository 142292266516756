<template>
  <div class="error-404 d-flex align-items-center justify-content-center">
    <div class="container">
      <div class="card py-5">
        <div class="row g-0">
          <div class="col col-xl-5">
            <div class="card-body p-4">
              <h1 class="display-1"><span class="text-primary">4</span><span class="text-danger">0</span><span class="text-success">4</span></h1>
              <h2 class="font-weight-bold display-4">Page introuvable </h2>
              <p>You have reached the edge of the universe.
                <br>The page you requested could not be found.
                <br>Dont'worry and return to the previous page.</p>
              <div class="mt-5">
                <router-link  to="/admin/dashboard" class="btn btn-primary btn-lg px-md-5 radius-30">Accueil</router-link>
<!--                <router-link   :to="{name:'Dashboard'}" class="btn btn-outline-dark btn-lg ms-3 px-md-5 radius-30">Accueil</router-link>-->
              </div>
            </div>
          </div>
          <div class="col-xl-7">
            <img src="https://cdn.searchenginejournal.com/wp-content/uploads/2019/03/shutterstock_1338315902.png" class="img-fluid" alt="">
          </div>
        </div>
        <!--end row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>