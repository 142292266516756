<template>
  <Breadcrumb :menu="bread"/>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8">

              <form-product @addProduct="addProduct" :baseUrl="baseUrl" :updateItem="updateItem"/>

            </div>
          </div>


        </div>


      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
            <b-spinner variant="primary"  v-if="loading" />
            <list-product v-else @updateProduct="updateProduct" @deleteProduct="deleteProduct" :datas="datas" :baseUrl="baseUrl"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script setup>
import Breadcrumb from "../../components/elements/breadcrumb";
import FormProduct from "../../components/elements/products/formProduct";
import ListProduct from "../../components/elements/products/listProduct";
import { onMounted} from "vue";
import useGetdata from "../../composable/useGetdata";
import {ref} from "@vue/reactivity";


const baseUrl = '/admin/products'

const updateItem = ref({})

const bread = {
  title:null,
  link:'/admin/users/store',
  links:[
    { title:'Produits',link:'/admin/produits/index'},
    { title:'Liste des produitss',link:'/admin/produits/index'}
  ]
}

const { loading ,datas , fetchData} =  useGetdata(baseUrl)

const addProduct = (data)=>{
  if (!data.data.id)
   datas.value.data.unshift(data.data)
  fetchData()
}
const deleteProduct = ()=>{
  fetchData()
  // alert('fdfff')
}
const updateProduct = (prod)=>{
    updateItem.value = prod
}

onMounted(  ()=>{
    fetchData()
})
</script>

<style scoped>

</style>
