<template>
  <Breadcrumb :menu="bread"/>

  <div class="card">
    <div class="card-body">

      <div class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;"  v-if="isLoading"></b-spinner>
      </div>

      <b-overlay :show="isLoading" rounded="sm">

      </b-overlay>

      <!-- description="We'll never share your email with anyone else."-->
      <div class="row">
        <div class="col-lg-6">
          <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
            <b-form-group
                class="form-invalid"
                id="input-group-1"
                label="Nom ticket:"
                label-for="input-1"
            >
              <b-form-input
                  :class="{'is-invalid':errors['name']}"
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Enter name"
                  required
              ></b-form-input>
              <div  class="invalid-feedback">{{errors.name?.join(' ')}}</div>
            </b-form-group>


            <div class="alert alert-danger" v-if="errors['products']">
              <div  class="text-danger">{{errors.products?.join(' ')}}</div>
            </div>

            <br>

            <b-button type="submit" variant="primary">
              <div class="span" v-if="form.id">
                  <span class="">
                    <span class="mr-2">Modifier</span>
                     <i  class="fadeIn animated bx bx-pencil"></i>
                    <b-spinner  v-if="isLoading" small variant="light"></b-spinner>
                  </span>
              </div>

              <div class="span" v-else>
                 <span class="" v-if="isLoading">
                    <b-spinner small variant="light"></b-spinner>
                  </span>
                <span v-else>
                    <i class="fadeIn animated bx bx-plus-circle"></i> Ajouter
                </span>
              </div>
            </b-button>
            <b-button  v-if="!form.id" type="reset" class="mx-3" variant="danger">Reset</b-button>
          </b-form>
          <div >
            <added-product-list />
          </div>


        </div>

        <div class="col-lg-6">


        <tikets-products @add-productitem="onAddProductitem"/>

        </div>
      </div>



    </div>
  </div>



  <!-- Button trigger modal -->
<!--  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">-->
<!--    Launch demo modal-->
<!--  </button>-->

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
/* eslint-disable */

import Breadcrumb from "../../components/elements/breadcrumb";
import {ref, nextTick, onMounted, watch,} from 'vue'
import {useRoute,useRouter} from "vue-router";
import {createToast} from "mosha-vue-toastify";
import useFetch from "../../composable/useFetch";
import TiketsProducts from "../../components/elements/products/tiketsProducts";
import AddedProductList from "../../components/elements/products/addedProductList";

import {useStore} from "vuex";
import {axiosService} from "../../services/axios.service";
// import _ from "lodash";
const route = useRoute()
const router = useRouter()
const store = useStore()
const  id = route.params.id
const basUrl = ref('/admin/tickets');
const show = ref(true)




const errors = ref([])

const cartProducts =ref( store.getters["cart/products"] )

watch ( cartProducts.value,   (currentValue) => {

  form.value.products = currentValue.map(ele=>{
    return {
      qty:ele.quantity,
      product_id:ele.product.id
    }
  })

});

const form = ref({
  name: '',
  products:[]

})
const { isLoading}  = useFetch(basUrl.value)
const bread = {
  title:'Ajouter un ticket ',
  link:'/admin/tickets/store',
  links:[
    { title:'Ticket',link:'/admin/tickets/index'},
    { title:'Liste des tickets',link:'/admin/tickets/index'},
    { title:'Ajouter un ticket ',link:'/admin/tickets/store'}
  ]
}

onMounted(async ()=>{

  if (id ){
    const res = await axiosService.get(basUrl.value+'/'+id)
    form.value = res.data
    // const products = res.data.data.map(ele=>{
    //   return {
    //     product:ele,
    //     quantity:ele.pivot.quantity
    //   }
    // })

 // await   store.commit('cart/SET_INT_PRODUCT', products)
 //    console.log(products)
    form.value.online = res.data.online === 1
    console.log(res.data)
    const produits = res.data.products.map(ele=>{
      return {
        product:ele,
        quantity:ele.pivot.quantity
      }
    })
    store.commit('cart/SET_INT_PRODUCT', produits)
  }
})

const onAddProductitem =(prod)=>{
  console.log(prod.id)

}

const onSubmit =async () => {

  try {
    form.value.products =  store.getters['cart/products'].map(ele=>{
      return {
        qty:ele.quantity,
        product_id:ele.product.id
      }
    })
    let res ={}
    if (id){
        res = await  axiosService.put(basUrl.value+'/'+id,form.value)
    }else {
        res = await  axiosService.post(basUrl.value,form.value)
    }


    console.log(res.data)
    createToast('Le ticker a bien été ajouté ',{type:"success",timeout:10000})
    store.commit('cart/VIDER_CART')
    router.push('/admin/tickets/index')
  }catch (e) {
    console.log(e)
    // errors.value = e.response.data.errors
    createToast('Merci de corriger vos erreurs ' ,{type:"danger"})
  }


}

const onReset = (event) => {
  event.preventDefault()
  form.value = ''

  nextTick(() => {
    show.value = true
  })
}
</script>

<style></style>
