<template>
  <!--breadcrumb-->
  <Breadcrumb :menu="bread"/>
  <!--end breadcrumb-->
  <!--  <h6 class="mb-0 text-uppercase">Liste des utilisateurs</h6>-->
  <!--  <hr/>-->
  <div class="card">
    <div class="card-body">

      <div class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;"  v-if="isLoading"></b-spinner>
      </div>

      <div class="table-responsive" >
        <h2>Liste des Packages </h2>

        <input type="text" name="" class="form-control" id="" v-model="q" >
        <hr>
        <h3>
          {{q }}
        </h3>
        <b-overlay :show="isLoading" rounded="sm">
          <table id="example2" class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Id</th>
              <th>Nom du ticket</th>


              <th>Montant </th>
              <th>Produits </th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>


            <tr  v-for="ticket in tickets" :key="ticket.id">
              <td>{{ticket.id}}</td>
              <td>{{ticket.name}}</td>
              <td>{{ticket.total}} CFA</td>

              <td>
                <b-badge variant="dark m-1"  v-for="(product, index) in products(ticket.products)  " :key="index"> {{product }}</b-badge>
              </td>


              <td>
                <div class="d-flex order-actions">
                  <a
                      href="#"
                      @click.prevent="deleteUser(ticket.id)"
                      class="text-danger bg-light-danger border-0"
                  ><i class="bx bxs-trash"></i
                  ></a>
                  <router-link :to="{name:'EditTickets',params:{id:ticket.id}}"
                               class="ms-4 text-primary bg-light-primary border-0"
                  ><i class="bx bxs-edit"></i
                  ></router-link>

<!--                  <router-link :to="{name:'EditTickets',params:{id:ticket.id}}"-->
<!--                               class="ms-4 text-primary bg-light-primary border-0"-->
<!--                  ><i class="bx bxs-user"></i-->
<!--                  ></router-link>-->
                </div>
              </td>
            </tr>

            </tbody>
            <tfoot>
            <tr>
              <th>Id</th>
              <th>Nom du ticket</th>


              <th>Actions</th>
            </tr>
            </tfoot>
          </table>
        </b-overlay>
      </div>


      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <!--          {{users.meta}}-->
          <li class="page-item" :class="{'active':p.active}" v-for="(p , index) in lists.meta?.links" :key="index" @click.prevent="goTo(p.url)">
            <button class="page-link"  v-html="p.label" ></button>
          </li>

        </ul>
      </nav>
    </div>
  </div>



<!--  <pre>-->
<!--    {{tickets}}-->
<!--  </pre>-->

</template>

<script setup>


import useFetch from "../../composable/useFetch";
import {computed, onMounted} from "vue";
import {ref} from "@vue/reactivity";
import Breadcrumb from "../../components/elements/breadcrumb";


const basUrl = ref('/admin/tickets');
const {lists , getResultsLink,isLoading , q,deleteModel}  = useFetch(basUrl.value)

const bread = {
  title:'Ajouter un Packages ',
  link:'/admin/tickets/store',
  links:[
    { title:'Packages',link:'/admin/tickets/index'},
    { title:'Liste des Packages',link:'/admin/tickets/index'}
  ]
}
const goTo = (url) => {
  basUrl.basUrl = url
  getResultsLink( basUrl.basUrl)
}

const tickets = computed(()=>{
  return  lists.value.data ;
})
const products = (products) =>{
  return products && products.map(ele=>ele.name) ;
}
onMounted(() => {
  getResultsLink()
})

const deleteUser = (id)=>{
  const rep = confirm("Supprimer l'enregistrement ?")
  rep? deleteModel(id) : null

  return   getResultsLink()


}

</script>

<style></style>
