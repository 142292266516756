<template>
  <div class="card">
    <div class="card-body">

      <div class="col form-group">
            <select class="form-control" v-on:change="changePageSize">
              <option value="4">4 per page</option>
              <option value="8">8 per page</option>
              <option value="12">12 per page</option>
            </select>
         </div>

      <div class="my-2  ">
        <input type="text" v-model="q" class="form-control">
      </div>
      <table class="table mb-0">
        <thead class="table-success">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nom</th>
          <th scope="col">Prix</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(prod , index) in products" :key="index">
          <th >{{prod.id}}</th>
          <td >{{prod.name}}</td>
          <td> <strong>{{prod.price}}</strong></td>
          <td>
            <button @click.prevent="ajoutAuPanier(prod)" class="btn btn-danger btn-sm btn--map">Ajouter </button>
          </td>
        </tr>
        </tbody>
      </table>

      <b-pagination
          v-model="curreentPage"
          :total-rows="store.getters['product/productsTotal']"
          :per-page="parpage"
          first-number
      ></b-pagination>



    </div>
  </div>


</template>

<script setup>

import {ref,} from "@vue/reactivity";
import {axiosService} from "../../../services/axios.service";
import {computed, defineEmits, onMounted, watch} from "vue";
import { useStore } from "vuex"
const store = useStore()
const emits = defineEmits(['add-productitem'])
const loading = ref(false)
const curreentPage = ref(1)
const parpage = ref(5)
const q = ref('')


watch(curreentPage , (value)=>{
  store.commit('product/SET_CURRENTEPAGE',value)
})

const products = computed(()=>{
  return store.getters["product/produitsTraite"]
})

// const lines = computed(()=>{
//   return store.getters["cart/products"]
// })
const loadProducts =async ()=>{
  loading.value = true
  try {
    const rep = await axiosService.get('/admin/products')
    await  store.dispatch('product/GET_PRODUCTS', rep.data.data)
    loading.value = false
  }catch (e) {
    loading.value = false
  }
}

const ajoutAuPanier = (prod)=>{
 store.commit('cart/ADD_PRODUCT',prod)
  emits('add-productitem',prod)
}

const pageNombers = ()=>{
  console.log(store.getters["product/pageCount"])
  return store.getters["product/pageCount"]
}

onMounted(()=>{
  loadProducts()
  pageNombers()
  store.commit('product/SET_PAGESIZE',parpage)
})
</script>

<style scoped>

</style>
