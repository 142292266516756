import {axiosService} from "../services/axios.service";



const user =  JSON.parse( localStorage.getItem('user_novissi') )

const initialState = user
    ? {
        status: { loggedIn: true },
        user,loading:false
    }
    : {
        status: { loggedIn: false },
        user: null,loading:false
    };

const  state =  initialState

const getters = {
    currentuser : (state)=> state.currentuser
}

const actions = {
  async  login({ commit }, user) {

      try {
          const rep = await axiosService.post('/user-login', user)
          commit('loginSuccess', rep.data.user);
      }catch (e) {
          commit('loginFailure');
      }

    },
    async logout({ commit }) {

        // await  axiosService.post('/logout')
        localStorage.removeItem('user_novissi')
        commit('logout');
    },
    async register({ commit }, user) {

        try {
             await axiosService.post('/user-register', user)
            commit('registerSuccess');
        }catch (e) {
            commit('registerFailure');
        }
    }
}
const  mutations = {
    loginSuccess(state, user) {
        state.status.loggedIn = true;
        state.user = user;
    },
    loginFailure(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    logout(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    registerSuccess(state) {
        state.status.loggedIn = false;
    },
    registerFailure(state) {
        state.status.loggedIn = false;
    },
    SET_LOADINGF(state,payload) {
        state.status.loading = payload;
    }
}



export default {
    strict: true,
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}