<template>
<div class="d-flex align-items-center justify-content-between flex-column">
  <h6 class="text-danger">  {{nbCout}}  Bénéficiaires </h6>
</div>

</template>

<script setup>
import {defineProps, onMounted, ref} from 'vue'
import {axiosService} from "../../services/axios.service";
const props = defineProps(['package_id','editionnovissi_id'])
const loading = ref(false)
const nbCout = ref(0)


onMounted(async ()=>{
  loading.value = true
  try {
   const res = await axiosService.get(`/admin/profile-package-edition?package_id=${props.package_id}&editionnovissi_id=${props.editionnovissi_id}`)
    nbCout.value = res.data
  }catch (e) {
    console.log(e)
  }finally {
    loading.value = false
  }
})


</script>

<style scoped>

</style>
