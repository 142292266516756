<template>
<div class="">
  <h3>Operation</h3>
</div>

  <div class="card">
    <div class="card-body">
      <table class="table table-bordered mb-0" v-if="loading">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Edition</th>
          <th scope="col">Bénéficiaire</th>
          <th scope="col">Num</th>
          <th scope="col">Date</th>
          <th scope="col">Observation</th>
          <th scope="col">Apération</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="operation in datas" :key="operation.id">
          <th scope="row">{{operation.id}}</th>
          <td>{{operation.editionnovissi['name']}}</td>
          <td>{{operation.user.profil.fullname}}</td>
          <td>{{operation.user.profil.telephone}}</td>

          <td>{{convertTodat(operation.created_at) }}</td>
          <td>{{ operation.observation}}</td>
          <td>
            <button class="btn btn-primary btn-sm">Operation</button>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>



</template>

<script setup>


import {onMounted} from "vue";
import convertTodat from "../../_helpers/dateHerper";
import {useRoute} from "vue-router";
import {ref} from "vue";
import {axiosService} from "../../services/axios.service";

const route = useRoute()

console.log('operation-editions =' + route.params.id)

const loading = ref(false)
const datas = ref()
 onMounted( async ()=>{
    await  getNovissi()
 })

const getNovissi = async ()=>{
    const url = `/admin/operations/${route.params.id}/editionnovissi`
   try {
     const res =  await axiosService.get(url)
     datas.value = res.data.data
   }catch (e) {
     console.log(e.data)
   } finally {
     loading.value = true
   }
 }
</script>

<style scoped>

</style>
